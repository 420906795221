<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div class="main" data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%2011.31.29%E2%80%AFa.m..png?alt=media&token=f476aa21-572b-43da-8d0c-9fccfda5b42f"></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Hackaton Smartcities</a>
      </h3>
      <span><a href="#">7 Marzo 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div class="main" data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%2011.31.29%E2%80%AFa.m..png?alt=media&token=f476aa21-572b-43da-8d0c-9fccfda5b42f"></div>
        </div>
        <div class="news_main_title">
          <div style="width: 100%;">
            <audio controls style="width: 100%">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-hackaton.mp3?alt=media&token=af6fed55-c86f-4142-84e6-ba39fecc29e6"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          </div>
          <h3>Hackaton Smartcities</h3>
          <span><a href="#">7 Marzo 2024</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            Todo comenzó en la Universidad Tecnológica de Hermosillo (ITH), donde se llevó a cabo el Hackathon SmartCities, un evento con inscripción abierta para todos aquellos apasionados por la tecnología y la innovación. Fue en este entorno dinámico y colaborativo donde nació la idea que hoy impulsa nuestro proyecto de monitoreo de residuos.          </p>
          <p>
            La competencia reunió a estudiantes, profesionales y entusiastas con el objetivo de desarrollar soluciones innovadoras para los problemas urbanos. En este contexto, nuestro equipo se formó casi por casualidad, unido por un objetivo común: encontrar una manera efectiva y económica de abordar el problema del vertido ilegal de basura en nuestra ciudad.          </p>
          <p>
            Durante el evento, discutimos y evaluamos diferentes propuestas, enfocándonos en crear un sistema de cámaras accesibles y de bajo costo que pudiera instalarse en puntos críticos de la ciudad para monitorear y registrar estos incidentes. La idea era simple pero poderosa: utilizar tecnología asequible para vigilar las zonas más afectadas, generando evidencia visual que pudiera ayudar a las autoridades a tomar acción.
          </p>
          <p>
            Nuestra propuesta inicial se centró en la instalación de cámaras económicas alimentadas por energía solar, capaces de capturar imágenes y videos que serían revisados manualmente. Aunque no contábamos con sofisticados algoritmos ni inteligencia artificial en ese momento, la meta era crear una solución práctica y asequible que pudiera implementarse fácilmente en las zonas más problemáticas.
          </p>
          <p>
            Este enfoque nos llevó a obtener el tercer lugar en el hackathon, siendo reconocidos por nuestra creatividad y por ofrecer una solución viable a un problema urbano significativo. Fue un gran impulso para nosotros, motivándonos a seguir desarrollando y mejorando la idea.
          </p>
          <p>
            El Hackathon SmartCities marcó el inicio de nuestro camino. Aunque nuestro proyecto ha evolucionado desde entonces, el espíritu de crear soluciones accesibles y efectivas sigue siendo el núcleo de nuestra misión. Este evento no solo nos dio la oportunidad de materializar una idea, sino también de conectar con personas e instituciones que comparten nuestra visión de un entorno urbano más limpio y ordenado.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
