<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%203.41.49%E2%80%AFp.m..png?alt=media&token=8ebd3d28-91dd-4d81-8db2-b38cac7142e8"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Tercer Proptotipo</a>
      </h3>
      <span><a href="#">23 Septiembre 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%203.41.49%E2%80%AFp.m..png?alt=media&token=8ebd3d28-91dd-4d81-8db2-b38cac7142e8"
          ></div>
        </div>
        <div class="news_main_title">
          <audio controls style="width: 100%">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-tercer%20prototipo.mp3?alt=media&token=acb0d0aa-db23-4f7d-96f7-1bd57c9d7936"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          <h3>Tercer Prototipo - Autonomía, Control Remoto y Resistencia Mejorados</h3>
          <span><a href="#">23 Septiembre 2024</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            En la evolución continua de nuestro sistema de monitoreo de vertidos ilegales de basura,
            hemos desarrollado el tercer prototipo, el cual presenta mejoras significativas en
            cuanto a autonomía, eficiencia energética y resistencia ambiental. Este nuevo diseño
            incorpora tecnologías avanzadas que permiten una operación más robusta y adaptable en
            entornos desafiantes, acercándonos cada vez más a una solución completamente operativa
            en el campo.
          </p>
          <p>
            El tercer prototipo ahora incluye una batería que soporta "PassThrough", lo que
            significa que puede cargarse y suministrar energía al mismo tiempo. Esta capacidad es
            fundamental para su funcionamiento continuo, ya que permite conectar el dispositivo a un
            panel solar sin interrumpir la operación de la cámara y la antena 3G/4G. De esta manera,
            el sistema puede mantenerse activo incluso durante la carga, asegurando una vigilancia
            constante en los puntos críticos.
          </p>
          <p>
            Además, hemos integrado una batería interna adicional que garantiza un voltaje
            constante. Esta mejora elimina los problemas de inestabilidad eléctrica que
            experimentamos en versiones anteriores, como los reinicios inesperados de la antena de
            internet. Ahora, el sistema puede operar de manera más fiable y consistente, evitando
            interrupciones en la transmisión de datos. Adicionalmente, esta batería interna cuenta
            con un monitor que nos permite verificar el estado de la batería de forma remota,
            brindándonos información en tiempo real sobre el nivel de carga y la salud del sistema
            energético.
          </p>
          <p>
            Otra innovación clave en este prototipo es la capacidad de ahorro de energía mediante un
            PowerBoost, que nos permite controlar de manera remota el encendido y apagado de la
            antena de internet y la cámara. Con esta funcionalidad, podemos gestionar el consumo
            energético de manera eficiente, activando el sistema solo cuando se detecta movimiento o
            en momentos específicos del día, y apagándolo cuando no es necesario, maximizando así la
            duración de la batería.
          </p>
          <p>
            Para mejorar la durabilidad del sistema en condiciones ambientales adversas, hemos
            añadido un ventilador interno que ayuda a reducir la temperatura dentro de la caja,
            evitando el sobrecalentamiento de los componentes electrónicos en días calurosos. Esta
            adición es crucial para mantener un rendimiento óptimo, especialmente en los entornos
            extremos en los que operará el dispositivo.
          </p>
          <p>
            Además, hemos diseñado la caja del sistema para que sea a prueba de agua, asegurando su
            funcionamiento incluso en condiciones de lluvia o alta humedad. Esta protección contra
            el agua nos permite instalar el dispositivo en exteriores sin temor a que se dañe por la
            exposición a los elementos, garantizando su operatividad en todas las estaciones del
            año.
          </p>
          <p>
            Con estas mejoras, el tercer prototipo se ha convertido en una solución más completa y
            resistente para el monitoreo de vertidos ilegales. La combinación de autonomía
            energética, control remoto, reducción de temperatura y resistencia al agua nos permite
            enfrentar de manera efectiva los desafíos que encontramos en el campo, asegurando un
            monitoreo constante y fiable en los puntos críticos.
          </p>
          <p>
            Seguimos trabajando para perfeccionar el diseño y asegurar que nuestro sistema sea capaz
            de enfrentar cualquier desafío en el campo, proporcionando una solución tecnológica
            sólida para combatir el vertido ilegal de basura en nuestra ciudad. Estamos convencidos
            de que, con estas nuevas capacidades, estamos más cerca de implementar un sistema de
            monitoreo efectivo y sostenible que ayudará a proteger nuestro entorno y a mejorar la
            calidad de vida en nuestra comunidad.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
