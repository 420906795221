<template>
  <div class="edrea_tm_section" id="portfolio">
    <div class="section_inner">
      <div class="edrea_tm_portfolio swiper-section">
        <div class="edrea_tm_main_title">
          <h3>Nuestros <span class="coloring">Logros</span></h3>
        </div>
        <div class="portfolio_list gallery_zoom">
          <swiper
            :loop="false"
            :slidesPerView="1"
            :spaceBetween="0"
            :loopAdditionalSlides="1"
            :autoplay="{
              delay: 6000,
            }"
            :navigation="{
              nextEl: '.my_next',
              prevEl: '.my_prev',
            }"
            :pagination="pagination"
            :breakpoints="{
              700: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }"
            @slideChange="onSlideChange"
            :modules="modules"
            class="swiper-container"
          >

          <!-- slides -->
          <swiper-slide class="swiper-slide">
            <MonitorSlide />
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <PodcastSlide />
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <PlatformSlide />
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <VisitaSlide />
          </swiper-slide>
          <swiper-slide class="swiper-slide">
            <SpeechesSlide />
          </swiper-slide>




            <div class="edrea_tm_swiper_progress fill">
              <div class="my_pagination_in">
                <span class="current">0</span>
                <span class="pagination_progress"
                  ><span class="all"
                    ><span
                      style="transform: translate3d(0px, 0px, 0px) scaleX(0.75) scaleY(1)"
                    ></span></span
                ></span>
                <span class="total">04</span>
              </div>
              <div class="my_navigation">
                <ul>
                  <li>
                    <a class="my_prev" href="#"><i class="icon-left-open-1"></i></a>
                  </li>
                  <li>
                    <a class="my_next" href="#"><i class="icon-right-open-1"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper core and required modules

// Import Swiper Vue.js components
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { swiperSliderCustomSlider } from "../utilits";
import MonitorSlide from "./slides/MonitorSlide.vue";
import PodcastSlide from "./slides/PodcastSlide.vue";
import PlatformSlide from "./slides/PlatformSlide.vue";
import VisitaSlide from "./slides/VisitaSlide.vue";
import SpeechesSlide from "./slides/SpeechesSlide.vue";

export default {
  name: `PortfolioComponent`,
  components: {
    Swiper,
    SwiperSlide,
    MonitorSlide,
    PodcastSlide,
    PlatformSlide,
    VisitaSlide,
    SpeechesSlide
  },
  methods: {
    onSlideChange(swiper) {
      this.activeSlider = swiper.activeIndex + 1;
    },
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
      pagination: {
        el: ".edrea_tm_swiper_progress",
        type: "custom", // progressbar
        renderCustom: function (swiper, current, total) {
          swiperSliderCustomSlider(current, total);
        },
      },
    };
  },
};
</script>
