<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%2012.38.37%E2%80%AFp.m..png?alt=media&token=fa02d5b7-451e-4f44-bc6b-90ba15dd388c"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Un Paso hacia la Colaboración con el Gobierno</a>
      </h3>
      <span><a href="#">23 Marzo 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%2012.38.37%E2%80%AFp.m..png?alt=media&token=fa02d5b7-451e-4f44-bc6b-90ba15dd388c"
          ></div>
        </div>
        <div class="news_main_title">
          <div style="width: 100%">
            <audio controls style="width: 100%">
              <source
                src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-agora.mp3?alt=media&token=f40e6543-580a-4acb-bf62-d0633dfbf41d"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </div>
          <h3>Un Paso hacia la Colaboración con el Gobierno</h3>
          <span><a href="#">23 Marzo 2024</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            Otro momento crucial en la evolución de nuestro proyecto fue la presentación que
            realizamos en el Ágora de la Universidad Tecnológica de Hermosillo (ITH), donde tuvimos
            la oportunidad de exponer nuestra iniciativa frente al presidente municipal y diversos
            funcionarios encargados de la gestión urbana y el medio ambiente de la ciudad.
          </p>
          <p>
            Durante esta presentación, mostramos la visión de nuestro proyecto para combatir el
            vertido ilegal de basura en puntos críticos de Hermosillo. A pesar de que en ese momento
            nuestro prototipo era solo una plataforma digital que simulaba un sistema de monitoreo
            con un mapa interactivo y alertas visuales, el interés generado fue notable. Explicamos
            cómo esta herramienta podría transformarse en un sistema completo con cámaras
            inteligentes y monitoreo en tiempo real para optimizar la respuesta de las autoridades.
          </p>
          <p>
            La participación en este evento fue un gran paso adelante para nosotros, ya que nos
            permitió establecer contacto directo con los líderes locales y explorar la posibilidad
            de una colaboración más estrecha con el gobierno municipal. Los funcionarios presentes
            mostraron interés en la implementación de una solución tecnológica como la nuestra para
            enfrentar los desafíos relacionados con el manejo de residuos sólidos en la ciudad.
          </p>
          <p>
            Esta presentación no solo nos brindó la oportunidad de dar a conocer nuestra propuesta a
            quienes tienen el poder de decisión en la ciudad, sino que también nos permitió recibir
            retroalimentación constructiva y adaptar nuestro enfoque para alinearnos mejor con las
            necesidades y prioridades del gobierno local. Salimos de ese encuentro con la certeza de
            que nuestra idea podía convertirse en una herramienta útil para la comunidad y con la
            motivación de seguir trabajando para hacerla realidad.
          </p>
          <p>
            El evento en el Ágora fue un punto de inflexión que reafirmó nuestro compromiso de
            transformar este proyecto en una solución efectiva para el manejo de residuos en
            Hermosillo, abriendo la puerta a futuras colaboraciones con las autoridades y a la
            posibilidad de llevar nuestra iniciativa al siguiente nivel.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
