<template>
  <div class="edrea_tm_topbar">
    <div class="topbar_inner">
      <div class="logo">
        <div style="display: flex; align-items: center">
            <a
              href="#"
              @click="navFuntions.activeSection('home')"
              style="display: flex; align-items: center"
            >
              <img
                style="border-radius: 50%; margin-right: 10px"
                src="https://firebasestorage.googleapis.com/v0/b/greenapp-a3d0b.appspot.com/o/assets%2FScreenshot%202024-03-07%20at%205.45.20%E2%80%AFp.m..png?alt=media&token=81c1f1be-2564-42f1-b178-d7ac9f6e63ca"
                alt="Logo"
              />
              <h4 class="coloring" style="margin: 0">Hargos</h4>
              
            </a>
          </div>
      </div>
      <div class="trigger">
        <div
          class="hamburger hamburger--slider"
          @click="navFuntions.toggleMobileMenu()"
        >
          <div class="hamburger-box">
            <div class="hamburger-inner"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="edrea_tm_mobile_menu"
    :class="navFuntions.mobileToggle ? 'opened' : ''"
  >
    <div class="inner">
      <div class="menu_list">
        <Menu />
      </div>
    </div>
  </div>
</template>

<script>
import { navFuntions } from "@/navFuntions";
import Menu from "./Menu.vue";
export default {
  name: `MobileHeaderComponent`,
  data() {
    return {
      navFuntions,
    };
  },
  components: { Menu },
};
</script>

<style scoped>
.edrea_tm_topbar,
.edrea_tm_mobile_menu {
  display: none;
}
@media (max-width: 1040px) {
  .edrea_tm_topbar,
  .edrea_tm_mobile_menu {
    display: block;
  }
}
</style>
