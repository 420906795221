// teamData.js
const OurAchievements = `
Monitoreo, detección de movimiento
Cámara inteligente
El sistema de monitoreo de tiro de basúra ilegal es una solución innovadora para combatir el problema del vertido ilegal de basura en áreas críticas de la ciudad. Utilizando cámaras inteligentes basadas en Raspberry Pi y alimentadas por paneles solares, este sistema permite la captura y transmisión de imágenes en tiempo real a una plataforma centralizada.
Cada cámara está equipada con un algoritmo de inteligencia artificial capaz de identificar y clasificar diferentes tipos de residuos, así como detectar la presencia de personas y vehículos en las zonas monitoreadas. La información recopilada se utiliza para generar reportes automáticos, que son enviados a las autoridades locales para una acción rápida y eficiente.
El sistema está diseñado para operar de manera autónoma en ubicaciones sin acceso a electricidad, gracias a su configuración de energía solar y a una batería de larga duración. Esta solución no solo ayuda a reducir los costos de limpieza pública, sino que también fomenta un comportamiento más responsable en la comunidad al disuadir a las personas de arrojar basura ilegalmente.

Plataforma de Gestión de Reportes
Página Web
La Plataforma de Gestión de Reportes es una propuesta innovadora que busca transformar la manera en que se administran los incidentes de vertidos ilegales en nuestra ciudad. Diseñada para mejorar la eficiencia y la coordinación de las autoridades locales, esta herramienta permitirá crear, visualizar y asignar reportes de manera interactiva, facilitando una respuesta rápida y efectiva ante situaciones críticas.
A futuro, esperamos que esta plataforma se convierta en un recurso esencial para las autoridades municipales, no solo para gestionar los residuos sólidos, sino también para enfrentar otros problemas urbanos, como la seguridad y el monitoreo ambiental. Con funcionalidades avanzadas de mapeo y filtros personalizados, la plataforma ofrecerá una visualización precisa y en tiempo real de los problemas urbanos, permitiendo una gestión proactiva y anticipada.
Nuestra visión es que esta herramienta sea implementada de manera integral en la ciudad, optimizando el uso de los recursos disponibles y promoviendo una cultura de transparencia y eficiencia en la administración pública. Con el apoyo adecuado, esperamos ampliar su alcance para que se convierta en una pieza clave en la modernización de la gestión urbana, contribuyendo a la creación de un entorno más limpio, seguro y ordenado para todos.
Esta propuesta representa un paso adelante hacia un futuro donde la tecnología y la colaboración ciudadana trabajen de la mano para resolver los desafíos de nuestra ciudad, y estamos comprometidos a seguir desarrollando y mejorando esta solución para alcanzar ese objetivo.

Recorrido con Patrulla Verde
Puntos Críticos
Gracias al recorrido que realizamos con la Patrulla Verde, pudimos adaptar nuestro proyecto para que pudiera instalarse en áreas sin acceso a conexión eléctrica. Durante estos recorridos, observamos de primera mano los puntos más críticos de vertido ilegal de basura y comprendimos las limitaciones de infraestructura en dichos lugares.
A partir de esta experiencia, desarrollamos un sistema de monitoreo autónomo, alimentado por energía solar, capaz de operar en condiciones adversas y en ubicaciones remotas. Esta adaptación nos permitió crear una solución más robusta y efectiva, que apoya de manera más eficiente la labor de la Patrulla Verde en la identificación y gestión de puntos críticos de residuos.
El resultado ha sido una propuesta conjunta de mejora que integra cámaras inteligentes y una plataforma de gestión que facilita el monitoreo en tiempo real, reforzando el esfuerzo colectivo para mantener nuestra ciudad más limpia y segura.

Recorrido con Patrulla Verde
Puntos Críticos
Gracias al recorrido que realizamos con la Patrulla Verde, pudimos adaptar nuestro proyecto para que pudiera instalarse en áreas sin acceso a conexión eléctrica. Durante estos recorridos, observamos de primera mano los puntos más críticos de vertido ilegal de basura y comprendimos las limitaciones de infraestructura en dichos lugares.
A partir de esta experiencia, desarrollamos un sistema de monitoreo autónomo, alimentado por energía solar, capaz de operar en condiciones adversas y en ubicaciones remotas. Esta adaptación nos permitió crear una solución más robusta y efectiva, que apoya de manera más eficiente la labor de la Patrulla Verde en la identificación y gestión de puntos críticos de residuos.
El resultado ha sido una propuesta conjunta de mejora que integra cámaras inteligentes y una plataforma de gestión que facilita el monitoreo en tiempo real, reforzando el esfuerzo colectivo para mantener nuestra ciudad más limpia y segura.
`;

module.exports = OurAchievements;