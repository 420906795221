<template>
  <div class="slider-section">
    <div class="label-row">
      <label>Eventos analizados por día: {{ events }}</label>
      <!-- Info icon for events -->
      <div
        class="info-icon"
        @mouseenter="showTooltip = true"
        @mouseleave="showTooltip = false"
        @mousemove="updateTooltipPosition"
      >
        i
        <!-- Tooltip for events -->
        <div v-if="showTooltip" :style="tooltipStyle" class="tooltip">
          Precio unitario por evento: ${{ pricePerEvent.toFixed(4) }} USD
        </div>
      </div>
    </div>
    <div class="slider-row">
      <input type="range" v-model="eventValue" :min="1" :max="5000" @input="updateEventValue" style="width: 70%;"  />
      <span class="price-contribution">
        ${{ eventContribution.toFixed(2) }} USD / día
        <br />
        ${{ (eventContribution * 30).toFixed(2) }} USD / mes
        <br />
        (1 Equipo)
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EventSlider",
  props: {
    events: {
      type: Number,
      required: true,
    },
    pricePerEvent: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      eventValue: this.events, // Internal value for the event slider
      showTooltip: false, // Track tooltip visibility
      tooltipX: 0, // X position of the tooltip
      tooltipY: 0, // Y position of the tooltip
    };
  },
  computed: {
    eventContribution() {
      return this.eventValue * this.pricePerEvent;
    },
    tooltipStyle() {
      return {
        top: `${0}px`,
        left: `${15}px`,
      };
    },
  },
  methods: {
    updateTooltipPosition(e) {
      this.tooltipX = e.clientX;
      this.tooltipY = e.clientY;
    },
    updateEventValue() {
      this.$emit("update-events", this.eventValue);
    },
  },
};
</script>

<style scoped>
.slider-section {
  margin: 20px 0;
}

.label-row {
  display: flex;
  align-items: center;
}

.info-icon {
  display: inline-block;
  background-color: #ddd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  white-space: nowrap;
  pointer-events: none;
  z-index: 100;
}

.slider-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-contribution {
  margin-left: 10px;
  font-weight: bold;
}
</style>
