<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/1-1.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fimgonline-com-ua-resize-d6cBP7HPSaDueBbR.jpg?alt=media&token=497b26dc-2367-4b09-a935-588d99c42f48"
      ></div>
    </div>
    <div class="details">
      <h3>Monitoreo con detección de movimiento</h3>
      <span>Camara Inteligente</span>
    </div>
    <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

    <!-- Portfolio Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="portfolio_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-3.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%205.00.58%E2%80%AFp.m..png?alt=media&token=707a0e69-3cbf-4478-8e4a-2aa97c81eb42"
            style="background-image: url('img/portfolio/4.jpg')"
          ></div>
        </div>
        <div class="details">
          <div style="width: 100%">
            <audio controls style="width: 100%">
              <source
                src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FMonitoreo%20deteccion%20de%20moviento.mp3?alt=media&token=70eaeed4-5a01-4f71-9a08-b5ebcd64cbe1"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </div>

          <h3>Monitoreo, detección de movimiento</h3>
          <span>Cámara inteligente</span>
        </div>
        <div class="text">
          <p>
            El sistema de monitoreo de tiro de basúra ilegal es una solución innovadora para
            combatir el problema del vertido ilegal de basura en áreas críticas de la ciudad.
            Utilizando cámaras inteligentes basadas en Raspberry Pi y alimentadas por paneles
            solares, este sistema permite la captura y transmisión de imágenes en tiempo real a una
            plataforma centralizada.
          </p>
          <p>
            Cada cámara está equipada con un algoritmo de inteligencia artificial capaz de
            identificar y clasificar diferentes tipos de residuos, así como detectar la presencia de
            personas y vehículos en las zonas monitoreadas. La información recopilada se utiliza
            para generar reportes automáticos, que son enviados a las autoridades locales para una
            acción rápida y eficiente.
          </p>
          <p>
            El sistema está diseñado para operar de manera autónoma en ubicaciones sin acceso a
            electricidad, gracias a su configuración de energía solar y a una batería de larga
            duración. Esta solución no solo ayuda a reducir los costos de limpieza pública, sino que
            también fomenta un comportamiento más responsable en la comunidad al disuadir a las
            personas de arrojar basura ilegalmente.
          </p>
        </div>
        <div class="additional_images">
          <ul class="gallery_zoom">
            <li>
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    target="_blank"
                    class="main"
                    data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%205.44.14%E2%80%AFp.m..png?alt=media&token=154e0376-ff5b-447f-812c-665817bb3687"
                  ></div>
                  <a
                    target="_blank"
                    class="edrea_tm_full_link zoom"
                    href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%205.44.14%E2%80%AFp.m..png?alt=media&token=154e0376-ff5b-447f-812c-665817bb3687"
                  ></a>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    target="_blank"
                    class="main"
                    data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%205.44.26%E2%80%AFp.m..png?alt=media&token=bfbeea68-f197-45da-afe6-e27a7589b290"
                  ></div>
                  <a
                    target="_blank"
                    class="edrea_tm_full_link zoom"
                    href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%205.44.26%E2%80%AFp.m..png?alt=media&token=bfbeea68-f197-45da-afe6-e27a7589b290"
                  ></a>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    target="_blank"
                    class="main"
                    data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%206.45.56%E2%80%AFp.m..png?alt=media&token=8e871595-e06d-4027-8bb2-b96bf87effad"
                  ></div>
                  <a
                    target="_blank"
                    class="edrea_tm_full_link zoom"
                    href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%206.45.56%E2%80%AFp.m..png?alt=media&token=8e871595-e06d-4027-8bb2-b96bf87effad"
                  ></a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Portfolio Popup Informations -->
  </div>
</template>

<script>
import { SwiperSlide } from "swiper/vue";

export default {
  components: {
    SwiperSlide,
  },
};
</script>

<style scoped>
  .list_inner .details {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    left: 20px;
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: all 0.3s ease;
  }
</style>
