<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%205.44.14%E2%80%AFp.m..png?alt=media&token=154e0376-ff5b-447f-812c-665817bb3687"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Integración con Inteligencia Artificial</a>
      </h3>
      <span><a href="#">30 Agosto 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%205.44.14%E2%80%AFp.m..png?alt=media&token=154e0376-ff5b-447f-812c-665817bb3687"
          ></div>
        </div>
        <div class="news_main_title">
          <audio controls style="width: 100%">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-integracion.mp3?alt=media&token=729bb955-13ff-4071-aa4b-8260235ed79a"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          <h3>Integración con Inteligencia Artificial</h3>
          <span><a href="#">30 Agosto 2024</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            A medida que nuestro proyecto avanzaba, nos dimos cuenta de que capturar imágenes de
            manera automática no era suficiente para abordar de manera efectiva el problema del
            vertido ilegal de basura. Necesitábamos una forma de analizar y clasificar las imágenes
            de manera inteligente, para que el sistema pudiera identificar de manera precisa los
            tipos de residuos y la actividad sospechosa en los puntos críticos. Así es como
            decidimos integrar la inteligencia artificial en nuestro proyecto.
          </p>
          <p>
            Exploramos tres opciones de tecnologías de inteligencia artificial para el análisis de
            imágenes: Vertex AI de Google, OpenAI Vision, y Amazon Rekognition. Cada una de estas
            plataformas ofrecía distintas ventajas en términos de precisión, facilidad de uso y
            capacidad de personalización. A continuación, se detalla nuestra experiencia con cada
            una de ellas:
          </p>
          <p>
            - <span class="coloring"> OpenAI Vision: </span>
            Esta opción nos ofrecía un análisis general de las imágenes, pero carecía de la
            capacidad de ser muy específica en la descripción de los elementos presentes. Aunque
            identificaba objetos y patrones, no alcanzaba el nivel de detalle que necesitábamos para
            nuestro propósito de monitoreo de residuos específicos y actividades relacionadas con el
            vertido ilegal.
          </p>
          <p>
            - <span class="coloring"> Amazon Rekognition: </span>
            La herramienta de Amazon mostró buenos resultados en la detección de objetos y en el
            reconocimiento facial, pero sus capacidades para describir escenas complejas y
            clasificar residuos no eran tan precisas como esperábamos. Aunque es una excelente
            opción para reconocimiento general, no cumplía con la necesidad de categorizar
            diferentes tipos de basura y vehículos en detalle.
          </p>
          <p>
            - <span class="coloring"> Vertex AI de Google: </span>
            Finalmente, encontramos en Vertex AI la solución que mejor se adaptaba a nuestras
            necesidades. Esta herramienta no solo ofrecía un análisis detallado y preciso de las
            imágenes, sino que también nos permitía personalizar los modelos y ser muy específicos
            en nuestros prompts. Podíamos entrenar el modelo para identificar y clasificar
            diferentes tipos de residuos, distinguir vehículos y personas en la escena, e incluso
            detectar patrones de comportamiento relacionados con el vertido ilegal.
          </p>
          <p>
            La capacidad de Vertex AI para generar descripciones detalladas de las imágenes fue un
            gran avance para nuestro proyecto. Con esta herramienta, pudimos implementar una
            solución que no solo detectaba la presencia de basura, sino que también proporcionaba
            información específica sobre el tipo de residuos y las circunstancias del vertido. Esto
            nos permitió generar reportes más completos y útiles para las autoridades, optimizando
            la respuesta ante estos incidentes.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
