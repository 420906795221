<template>
  <div class="edrea_tm_section" id="pricing">
    <div class="flex column">
      <div style="height: 80px"></div>

      <div class="plans-container">
        <div class="edrea_tm_contact_1 my-bordered-div">
          <!-- Content for Plan Básico -->
          <h3>Plan <span class="coloring"> Básico </span></h3>
          <p>Sistema básico de equipos inteligentes</p>
          <hr />
          <p><strong>$570</strong> USD / Equipo</p>
          <p><strong>$0.01</strong> USD / Evento</p>
          <ul>
            <li>
              <span style="color: green">✔️</span> Hasta
              <span class="coloring"> 10 millones</span> eventos guardadas
            </li>
            <li>
              <span style="color: green">✔️</span> Hasta
              <span class="coloring"> 18,000 (600 / día) </span> análisis eventos al mes
            </li>
            <li>
              <span style="color: green">✔️</span> Monitoreo en tiempo real de vertidos ilegales
            </li>
            <li>
              <span style="color: green">✔️</span> Generación de reportes automáticos por cada
              evento
            </li>
            <li>
              <span style="color: green">✔️</span> Acceso a plataforma de gestión de imágenes y
              reportes
            </li>
            <li><span style="color: green">✔️</span> Consulta de datos históricos de incidentes</li>
            <li><span style="color: green">✔️</span> Soporte técnico básico para la instalación</li>
            <li><span style="color: green">✔️</span> Acceso remoto para la gestión de equipos</li>
            <li><span style="color: green">✔️</span>Guía para el mantenimiento de los equipos</li>
            <li><span style="color: green">✔️</span> Conexión 3G/4G disponible</li>
            <li>
              <span style="color: green">✔️</span> Listo para implementar en cualquier zona crítica
            </li>
            <li><span style="color: green">✔️</span> Servidor dedicado</li>
            <li><span style="color: green">✔️</span> Notificaciones en tiempo read</li>
            <li><span style="color: green">✔️</span> Actualizaciones de software</li>

            <li><span>-</span> * Mantenimiento equipos</li>
            <li><span style="color: red">❌</span> Eventos ilimitados</li>
          </ul>
          <div class="monthly-card">
            Eventos de $6 USD / día
            <br />
            <small> por equipo</small>
          </div>
          <div class="monthly-card">
            <p>Mensualidad</p>
            <p>servidor + bases de datos</p>
            <p>$400 USD</p>
          </div>
        </div>

        <div class="edrea_tm_contact_1 my-bordered-div">
          <!-- Content for Plan Pro -->

          <div>
            <h3>
              Plan <span class="coloring"> Pro </span>
              <span class="discount-bubble" style="right: 20px"> AHORRA 38%</span>
            </h3>
          </div>
          Sistema pro de equipos inteligentes
          <hr />
          <p><s>$570</s> <strong>$465</strong> USD / Equipo</p>
          <p><s>$0.01</s> <strong>$0.0062</strong> USD / Evento</p>
          <ul>
            <li>
              <span style="color: green">✔️</span> Hasta
              <span class="coloring"> 50 millones </span> eventos guardadas
            </li>
            <li>
              <span style="color: green">✔️</span> Hasta
              <span class="coloring"> 75,000 (2500 / día)</span> eventos al més
            </li>
            <li>
              <span style="color: green">✔️</span> Monitoreo en tiempo real de vertidos ilegales
            </li>
            <li>
              <span style="color: green">✔️</span> Generación de reportes automáticos por cada
              evento
            </li>
            <li>
              <span style="color: green">✔️</span> Acceso a plataforma de gestión de imágenes y
              reportes
            </li>
            <li><span style="color: green">✔️</span> Consulta de datos históricos de incidentes</li>
            <li><span style="color: green">✔️</span> Soporte técnico básico para la instalación</li>
            <li><span style="color: green">✔️</span> Acceso remoto para la gestión de equipos</li>
            <li><span style="color: green">✔️</span>Guía para el mantenimiento de los equipos</li>
            <li><span style="color: green">✔️</span> Conexión 3G/4G disponible</li>
            <li>
              <span style="color: green">✔️</span> Listo para implementar en cualquier zona crítica
            </li>
            <li><span style="color: green">✔️</span> Servidor dedicado</li>
            <li><span style="color: green">✔️</span> Notificaciones en tiempo read</li>
            <li><span style="color: green">✔️</span> Actualizaciones de software</li>

            <li><span>-</span> * Mantenimiento equipos</li>
            <li><span style="color: red">❌</span> Eventos ilimitados</li>
          </ul>
          <div class="monthly-card">
            Eventos de $15.5 USD / día <br />
            <small> por equipo</small>
          </div>
          <div class="monthly-card">
            <p>Mensualidad</p>
            <p>servidor + bases de datos</p>
            <p>$530 USD</p>
          </div>
        </div>
      </div>
      <div class="edrea_tm_contact my-bordered-div" style="height: 100%">
        <div class="edrea_tm_main_title">
          <h3>
            Plan <span class="coloring">Ilimitado</span>
            <span class="discount-bubble" style="right: 20px"> AHORRA 48%</span>
          </h3>
          <small> Para aquellos proyectos que necesitan alto monitoreo </small>
        </div>
        <div class="wrapper flex-column" style="width: 100%">
          <!-- Sliders for number of cameras and events -->
          <p><s>$570</s> <strong>$387.12</strong> USD / Equipo</p>
          <p><s>$0.01</s> <strong>$0.0042</strong> USD / Evento</p>

          <CameraSlider
            :cameras="cameras"
            :pricePerCamera="pricePerCamera"
            @update-cameras="cameras = $event"
          />
          <EventSlider
            :events="events"
            :pricePerEvent="pricePerEvent"
            @update-events="events = $event"
          />
        </div>

        <!-- Display calculated price -->
        <div class="price-section">
          <div></div>
          <hr />
          <h3></h3>
          <div class="price-row">
            <h4>Precio Equipos</h4>
            <h4>${{ cameraContribution.toFixed(2) }} USD</h4>
          </div>
          <hr />

          <h3>Servidor + Bases de datos</h3>
          <div class="price-row">
            <h4>AI (Eventos Analizados):</h4>
            <span>${{ (eventContribution * 30 * cameras).toFixed(2) }} USD/mes</span>
          </div>
          <div class="price-row">
            <h4>Servidor AWS:</h4>
            <span>${{ awsServer.toFixed(2) }} USD/mes</span>
          </div>
          <div class="price-row">
            <h4>Servidor Realtime:</h4>
            <span>${{ realtimeServer.toFixed(2) }} USD/mes</span>
          </div>
          <div class="price-row">
            <h4>Almacenamiento de Imagenes (+100 millones eventos):</h4>
            <span>${{ storageFirebase.toFixed(2) }} USD/mes</span>
          </div>
          <div class="price-row">
            <h4>Base de datos:</h4>
            <span>${{ mongoDb.toFixed(2) }} USD/mes</span>
          </div>
          <hr />
          <div class="price-row">
            <h4>Costo total (mensual):</h4>
            <h4>
              ${{
                (
                  eventContribution * 30 * cameras +
                  awsServer +
                  storageFirebase +
                  mongoDb +
                  realtimeServer
                ).toFixed(2)
              }}
              USD/mes
            </h4>
          </div>
          <div class="price-row">
            <h4>Costo total (anual):</h4>
            <h4>
              ${{
                (
                 ( eventContribution * 30 * cameras +
                  awsServer +
                  storageFirebase +
                  mongoDb +
                  realtimeServer ) * 12
                ).toFixed(2) 
              }}
              USD/anual
            </h4>
          </div>
          <hr />
          <h3>Instalación y servicios</h3>
          <div class="price-row">
            <h4>Servicios de mantenimiento:</h4>
            <span>${{ services.toFixed(2) }} USD/mes</span>
          </div>
          <div class="price-row">
            <small
              >* esta seccion aun se sigue definiendo aqui se pondran los costos de tener operadores
              que hagan rondines en la ciudad costos de gasolina, salarios de operadores, costos de
              mantenimiento, etc.</small
            >
            <small></small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import CameraComponent from "./pricingPrototype/CameraComponent.vue";
import CameraSlider from "./pricingPrototype/CameraSlider.vue";
import EventSlider from "./pricingPrototype/EventSlider.vue";

export default {
  name: "ContactComponent",
  components: {
    CameraComponent,
    CameraSlider,
    EventSlider,
  },
  data() {
    return {
      cameras: 1, // Initial value for cameras
      events: 2500, // Initial value for events
      pricePerCamera: 387.12, // Fixed price per camera (one-time)
      pricePerEvent: 0.0042, // Recurring price per event (monthly)
      showTooltip: false, // Track tooltip visibility for events
      showCameraTooltip: false, // Track tooltip visibility for cameras
      tooltipX: 0, // X position of the tooltip
      tooltipY: 0, // Y position of the tooltip
      awsServer: 59.5585,
      storageFirebase: 25.87 * 10,
      mongoDb: 158.4,
      services: computed(() => {
        return (700 + 25 * 30) * Math.ceil(this.cameras / 15);
      }),
      realtimeServer: 34.5585,
    };
  },
  computed: {
    // Calculate the price contribution for cameras (fixed)
    cameraContribution() {
      return this.cameras * this.pricePerCamera;
    },
    // Calculate the price contribution for events (monthly)
    eventContribution() {
      return this.events * this.pricePerEvent;
    },
    // Tooltip style for positioning near the mouse cursor
    tooltipStyle() {
      return {
        top: `${0}px`, // Adjust position slightly below the cursor
        left: `${15}px`, // Adjust position slightly to the right of the cursor
      };
    },
  },
  methods: {
    updateTooltipPosition(e) {
      this.tooltipX = e.clientX;
      this.tooltipY = e.clientY;
    },
  },
};
</script>

<style scoped>
.monthly-card {
  background-color: #4caf50; /* Green background */
  color: white; /* White text */
  padding: 15px;
  border-radius: 8px; /* Rounded corners */
  text-align: center; /* Center the text */
  font-size: 1.2rem; /* Increase font size */
  font-weight: bold; /* Bold text */
  margin-top: 20px; /* Space above the card */
}
.my-bordered-div {
  border: 2px solid #d3d3d3; /* 2px wide solid border with dark grey color */
  padding: 15px; /* Padding inside the border */
  border-radius: 8px; /* Rounded corners for the border */
  background-color: rgba(0, 0, 0, 0.8); /* Light background color inside the border */
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Space between items */
}

.slider-section {
  margin: 20px 0;
}

.label-row {
  display: flex;
  align-items: center;
}

.info-icon {
  display: inline-block;
  background-color: #ddd;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  margin-left: 10px;
  cursor: pointer;
  position: relative;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: rgb(76, 184, 29);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  white-space: nowrap;
  pointer-events: none; /* Prevent the tooltip from blocking mouse events */
  z-index: 100;
}

.slider-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.price-contribution {
  margin-left: 10px;
  font-weight: bold;
}

.price-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.price-row h4 {
  margin: 0;
}

.price-row span {
  font-weight: bold;
}

input[type="range"] {
  width: 80%; /* Make the range input take up less space to fit the contribution */
}

/*  */

.plans-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; /* Makes the elements stack in smaller screens */
  margin: 0 auto;
  max-width: 1200px;
}

.edrea_tm_contact_1 {
  width: 48%; /* Take up half the screen on larger screens */
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  transition: box-shadow 0.3s;
}

.my-bordered-div:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Shadow on hover */
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin-bottom: 10px;
  color: #d3d3d3;
}

.cta-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff7f00;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #ff9900;
}

.discount-bubble {
  position: absolute;
  background-color: #ff0000; /* Red background for the bubble */
  color: white;
  padding: 5px 10px;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 50px; /* Rounded corners to make it look like a bubble */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

@media (max-width: 768px) {
  .edrea_tm_contact {
    width: 100%; /* Full width on mobile screens */
  }
}
</style>
