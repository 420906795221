<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/1-1.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fenvato-labs-ai-d71d7f64-48aa-4aac-946a-92be68ad0ff0.jpg?alt=media&token=dca5dd46-fcef-4fac-9749-9273ce3efda8"
      ></div>
    </div>
    <div class="details">
      <h3>Podcast Hargos</h3>
      <span>Conversación AI</span>
    </div>
    <a
      class="edrea_tm_full_link soundcloude_link mfp-iframe audio"
      href="https://w.soundcloud.com/player/?url=https%3A//soundcloud.com/jorge-andres-garcia-montiel/hargos-podcast&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
    ></a>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>


<style scoped>
  .list_inner .details {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    left: 20px;
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: all 0.3s ease;
  }
</style>