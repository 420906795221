<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%2011.45.30%E2%80%AFa.m..png?alt=media&token=1111dc17-7b58-4c08-b25e-38b8adc785a3"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Un Reconocimiento al Esfuerzo</a>
      </h3>
      <span><a href="#">12 Marzo 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%2011.45.30%E2%80%AFa.m..png?alt=media&token=1111dc17-7b58-4c08-b25e-38b8adc785a3"
          ></div>
        </div>
        <div class="news_main_title">
          <audio controls style="width: 100%;">
              <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-ruina.mp3?alt=media&token=026e0073-5a97-4754-960e-8edf3859ac30"
              type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          <h3>Un Reconocimiento al Esfuerzo</h3>
          <span><a href="#">10 Marzo 2024</a></span>
          <div class="link-container">
            <a
            style="color: white;"
              href="https://www.elimparcial.com/son/hermosillo/2024/03/14/jovenes-exponen-proyectos-a-favor-del-medio-ambiente-en-el-smart-city-hackathon-2024/"
              target="_blank"
              class="button-link"
            >
              Artículo Imparcial
            </a>
          </div>
        </div>
        <div class="text">
          <p>
            Uno de los momentos más importantes en el desarrollo de nuestro proyecto fue nuestra
            presentación en el evento organizado en Parque La Ruina, un centro de encuentro para
            ideas innovadoras y proyectos que buscan transformar Hermosillo. En esta ocasión,
            tuvimos la oportunidad de mostrar nuestra visión para enfrentar el problema del vertido
            ilegal de basura en la ciudad.
          </p>
          <p>
            Aunque en ese momento nuestro prototipo no contaba con hardware ni dispositivos de
            monitoreo, logramos desarrollar una plataforma digital que simulaba el funcionamiento de
            un sistema de monitoreo urbano. La página web, aún en una etapa inicial, mostraba un
            mapa interactivo con marcadores en los puntos críticos de vertido ilegal y algunas
            alertas visuales, representando cómo se podría gestionar la información y notificar a
            las autoridades en tiempo real.
          </p>
          <p>
            A pesar de ser un prototipo sin funcionalidad completa, nuestra propuesta fue bien
            recibida por su enfoque innovador y su potencial para convertirse en una solución
            efectiva. Explicamos cómo esta plataforma podría evolucionar en un sistema más robusto
            con cámaras y sensores que, en conjunto con la página, ofrecerían una herramienta
            poderosa para la administración y control de vertidos ilegales.
          </p>
          <p>
            Gracias a esta presentación, obtuvimos el tercer lugar en la competencia, un
            reconocimiento que nos llenó de motivación para seguir trabajando y desarrollando la
            idea. Como premio, recibimos dos computadoras y 5,000 pesos, recursos que utilizamos
            para continuar mejorando nuestra propuesta y avanzar hacia un sistema funcional.
          </p>
          <p>
            Este evento fue un punto de partida crucial para nuestro equipo, pues nos permitió
            recibir retroalimentación valiosa y darnos cuenta del gran interés que generaba nuestra
            idea, incluso en su fase más temprana. La experiencia en Parque La Ruina nos impulsó a
            transformar este concepto inicial en una solución tecnológica real que pudiera ayudar a
            mantener nuestra ciudad más limpia y ordenada.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>


<style scoped>
.news_main_title {
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.link-container {
  display: flex;
  justify-content: flex-start; /* Justificar el contenido a la derecha */
  margin-top: 10px; /* Espacio superior entre el botón y el texto */
}

.button-link {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff; /* Color de fondo azul */
  text-decoration: none; /* Quitar el subrayado */
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button-link:hover {
  background-color: #0056b3; /* Color al pasar el ratón */
}
</style>