<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%203.50.09%E2%80%AFp.m..png?alt=media&token=a2099990-f647-49e0-bee4-d1bc0a12db08"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Implementación en el Terreno</a>
      </h3>
      <span><a href="#">Futuro</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%203.50.09%E2%80%AFp.m..png?alt=media&token=a2099990-f647-49e0-bee4-d1bc0a12db08"
          ></div>
        </div>
        <div class="news_main_title">
          <audio controls style="width: 100%">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Ffuturo1.mp3?alt=media&token=cee70b14-3bf7-4790-8e3e-da4f57835818"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          
          <h3>Próximos Pasos - Implementación en el Terreno</h3>
          <span><a href="#">Futuro</a></span>
  
        </div>
        <div class="text">
          <p>
            Con el tercer prototipo completamente funcional, estamos listos para llevar nuestro
            sistema de monitoreo al siguiente nivel. Ahora buscamos el apoyo de la Patrulla Verde
            para asignarnos una ubicación específica en la ciudad donde podamos probar el
            dispositivo en condiciones reales.
          </p>
          <p>
            Esta primera implementación nos permitirá validar el rendimiento del prototipo en un
            entorno real, monitoreando un punto crítico de vertido ilegal de basura. Esperamos que
            este despliegue inicial demuestre la eficacia de nuestro sistema y siente las bases para
            expandir la red de monitoreo en más ubicaciones estratégicas de la ciudad.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
