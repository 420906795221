<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fimgonline-com-ua-resize-EJKm2hhSyPxcE6.jpg?alt=media&token=420a05a7-9cf3-47db-a622-b90e4b1ecfce"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Entendiendo los Desafíos del Proyecto</a>
      </h3>
      <span><a href="#">22 Junio 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fimgonline-com-ua-resize-EJKm2hhSyPxcE6.jpg?alt=media&token=420a05a7-9cf3-47db-a622-b90e4b1ecfce"
          ></div>
        </div>
        <div class="news_main_title">
          <div style="width: 100%">
            <audio controls style="width: 100%">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-desafios.mp3?alt=media&token=660e898d-60e0-4076-a188-9f64918fa025"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          </div>
          <h3>Entendiendo los Desafíos del Proyecto</h3>
          <span><a href="#">22 Junio 2024</a></span>
          <div class="link-container">
            <a
              style="color: white"
              href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fvideo%201.mp4?alt=media&token=c1b6f69c-48ed-4ff3-b4b5-535185b178d4"
              target="_blank"
              class="button-link"
            >
              Evidencia 1
            </a>
            <a
              style="color: white"
              href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fvideo%202.mp4?alt=media&token=43a83e4f-d5de-409d-8711-a565690aed84"
              target="_blank"
              class="button-link"
            >
              Evidencia 2
            </a>
            <a
              style="color: white"
              href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fvideo%203.mp4?alt=media&token=129006b6-e49d-42e1-b296-ce843d749e4e"
              target="_blank"
              class="button-link"
            >
              Evidencia 3
            </a>
            <a
              style="color: white"
              href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fvideo%204.mp4?alt=media&token=d1aced21-cb68-4937-9443-369bf195f618"
              target="_blank"
              class="button-link"
            >
              Evidencia 4
            </a>
          </div>
        </div>
        <div class="text">
          <p>
            Un paso clave en el desarrollo de nuestro proyecto fue el recorrido que realizamos junto
            a un conductor de la Patrulla Verde. Esta experiencia nos permitió conocer de primera
            mano los puntos críticos de vertido ilegal de basura en Hermosillo y entender mejor los
            desafíos que conlleva monitorear y controlar estas actividades en el terreno.
          </p>
          <p>
            Durante el recorrido, visitamos varios sitios problemáticos donde se arrojan grandes
            cantidades de basura y escombros, no solo por personas individuales, sino también por
            camionetas y camiones de construcción. Muchos de estos lugares se encuentran en zonas
            remotas, sin acceso a electricidad ni infraestructura básica, lo que representa un
            desafío considerable para cualquier tipo de monitoreo continuo.
          </p>
          <p>
            El conductor de la Patrulla Verde compartió con nosotros su experiencia diaria y cómo,
            en muchos casos, los infractores aprovechan momentos específicos de baja actividad para
            arrojar sus desechos, lo que dificulta atraparlos en el acto. Además, notamos que el
            equipo de la Patrulla Verde depende en gran medida del uso de WhatsApp para gestionar
            los reportes de vertido ilegal. Utilizan esta aplicación para recibir y enviar
            información, incluyendo imágenes y ubicaciones de los puntos críticos, lo que complica
            el seguimiento y la coordinación efectiva de acciones.
          </p>
          <p>
            Al observar esta situación, comprendimos la necesidad de una plataforma de gestión más
            estructurada y eficiente que centralizara la información y facilitara la coordinación en
            tiempo real. Decidimos que nuestro proyecto debía enfocarse no solo en el monitoreo
            visual, sino también en ofrecer una solución digital que mejorara la gestión de reportes
            y la comunicación interna del equipo.
          </p>
          <p>
            En cuanto a la logística de las cámaras, nos enfrentamos a varios desafíos. Dado que
            muchos de los puntos críticos están en áreas sin acceso a energía eléctrica, fue
            necesario desarrollar un sistema de cámaras completamente autónomo, alimentado por
            energía solar y equipado con baterías de larga duración. Además, en estos entornos, la
            conectividad es limitada, lo que nos llevó a implementar un sistema de transmisión de
            datos mediante redes 3G/4G para subir las fotos capturadas a la plataforma.
          </p>
          <p>
            Este recorrido fue fundamental para adaptar nuestro proyecto a las realidades del
            terreno y desarrollar una solución tecnológica viable y efectiva. A partir de esta
            experiencia, hemos trabajado en perfeccionar nuestro sistema, integrando cámaras
            autónomas, conectividad 3G y una plataforma digital que facilite la gestión de reportes
            y la coordinación con la Patrulla Verde.
          </p>
          <p>
            Esta experiencia no solo enriqueció nuestro proyecto, sino que también reforzó nuestro
            compromiso de seguir trabajando para crear un entorno urbano más limpio y sostenible,
            apoyando a quienes, como la Patrulla Verde, se esfuerzan diariamente por proteger
            nuestro entorno.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>

<style scoped>
.news_main_title {
  padding: 20px;
  border-radius: 5px;
  position: relative;
}

.link-container {
  display: flex;
  justify-content: flex-start; /* Justificar el contenido a la derecha */
  margin-top: 10px; /* Espacio superior entre el botón y el texto */
}

.button-link {
  padding: 10px 20px;
  margin: 4px;
  font-size: 16px;
  cursor: pointer;
  background-color: #356b06; /* Color de fondo azul */
  text-decoration: none; /* Quitar el subrayado */
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button-link:hover {
  background-color: #49692e; /* Color al pasar el ratón */
}
</style>
