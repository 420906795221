<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/1-1.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.13.35%E2%80%AFp.m..png?alt=media&token=52768699-b8ab-4843-ade5-e0a8a5792fcc"
      ></div>
    </div>
    <div class="details">
      <h3>Eventos y Reconocimientos</h3>
      <span>Presentaciones</span>
    </div>
    <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

    <!-- Portfolio Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="portfolio_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-3.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.13.35%E2%80%AFp.m..png?alt=media&token=52768699-b8ab-4843-ade5-e0a8a5792fcc"
            style="background-image: url('img/portfolio/4.jpg')"
          ></div>
        </div>
        <div class="details">
          <div style="width: 100%">
            <audio controls style="width: 100%">
              <source
                src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FEventos%20y%20presentaciones.mp3?alt=media&token=aca8388e-621d-472e-a8ed-87eaf1c76b2a"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </div>
          <h3>Eventos y Reconocimientos</h3>
          <span>Presentaciones</span>
        </div>
        <div class="text">
          <p>
            <span class="coloring" > Hackathon SmartCities: </span>
            Presentamos y desarrollamos la idea inicial de nuestro sistema de monitoreo de residuos
            durante este evento, obteniendo el tercer lugar y destacándonos por nuestra propuesta
            tecnológica innovadora. Este reconocimiento nos motivó a seguir adelante con el proyecto
            y a perfeccionarlo para su implementación en el mundo real.
          </p>
          <p>
            <span class="coloring" > Parque La Ruina: </span>
            Tuvimos la oportunidad de exponer nuestro prototipo en un evento público, lo que generó
            un gran interés por parte de la comunidad y de las autoridades locales. Esta experiencia
            no solo validó la relevancia de nuestra solución, sino que también nos permitió recibir
            valiosas retroalimentaciones y apoyo para continuar desarrollando nuestro proyecto.
          </p>
          <p>
            <span class="coloring" > Presentación en el Ágora: </span>
            Fuimos invitados a presentar nuestro proyecto frente al presidente municipal y diversos
            funcionarios de la ciudad, quienes mostraron interés en la iniciativa. Este encuentro
            nos brindó la oportunidad de exponer el impacto potencial de nuestra solución y explorar
            posibles colaboraciones para su implementación en la ciudad.
          </p>
        </div>
        <div class="additional_images">
          <ul class="gallery_zoom">
            <li>
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    target="_blank"
                    class="main"
                    data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.13.46%E2%80%AFp.m..png?alt=media&token=4302a820-0c71-4cee-8687-8b69a056764d"
                  ></div>
                  <a
                    target="_blank"
                    class="edrea_tm_full_link zoom"
                    href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.13.46%E2%80%AFp.m..png?alt=media&token=4302a820-0c71-4cee-8687-8b69a056764d"
                  ></a>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    target="_blank"
                    class="main"
                    data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.13.56%E2%80%AFp.m..png?alt=media&token=815f9191-7aa2-413a-98f0-d48ef2eaea21"
                  ></div>
                  <a
                    target="_blank"
                    class="edrea_tm_full_link zoom"
                    href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.13.56%E2%80%AFp.m..png?alt=media&token=815f9191-7aa2-413a-98f0-d48ef2eaea21"
                  ></a>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    target="_blank"
                    class="main"
                    data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.13.24%E2%80%AFp.m..png?alt=media&token=d77d5f6a-e79d-4596-9040-511c215faa9c"
                  ></div>
                  <a
                    target="_blank"
                    class="edrea_tm_full_link zoom"
                    href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.13.24%E2%80%AFp.m..png?alt=media&token=d77d5f6a-e79d-4596-9040-511c215faa9c"
                  ></a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Portfolio Popup Informations -->
  </div>
</template>

<script>
import { SwiperSlide } from "swiper/vue";

export default {
  components: {
    SwiperSlide,
  },
};
</script>


<style scoped>
  .list_inner .details {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    left: 20px;
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: all 0.3s ease;
  }
</style>