<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.44.11%E2%80%AFp.m..png?alt=media&token=2095c9c0-161d-44b2-8bb1-d6ea2ff4e149"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Plataforma de Análisis y Gestión de Reportes</a>
      </h3>
      <span><a href="#">7 Septiembre 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.44.11%E2%80%AFp.m..png?alt=media&token=2095c9c0-161d-44b2-8bb1-d6ea2ff4e149"
          ></div>
        </div>
        <div class="news_main_title">
          <audio controls style="width: 100%">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-plataforma.mp3?alt=media&token=05ccecf2-cce2-43d1-b9fc-3d896e70c67f"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          <h3>Versión Uno de la Plataforma de Análisis y Gestión de Reportes</h3>
          <span><a href="#">7 Septiembre 2024</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            Con el objetivo de optimizar el monitoreo y la gestión de vertidos ilegales de basura en
            la ciudad, hemos desarrollado la versión uno de nuestra plataforma digital, diseñada
            para analizar imágenes capturadas por nuestro sistema de cámaras y generar reportes de
            manera eficiente y automatizada. Esta herramienta marca un avance significativo en
            nuestro proyecto, ya que facilita la recopilación, el análisis y la visualización de
            datos en tiempo real, mejorando la respuesta ante estos incidentes.
          </p>
          <p>
            La plataforma integra las imágenes capturadas por nuestros prototipos de cámaras, que se
            suben automáticamente al servidor, y las procesa mediante inteligencia artificial para
            identificar la presencia de basura, personas o vehículos en las áreas monitoreadas.
            Gracias a la integración con Vertex AI de Google, el sistema es capaz de clasificar de
            manera precisa los distintos tipos de residuos y detectar patrones de comportamiento,
            proporcionando información detallada y contextualizada.
          </p>
          <p>
            Además de las capacidades de análisis, la plataforma permite la generación de reportes
            automáticos que se envían directamente a las autoridades locales, facilitando la toma de
            decisiones y la acción inmediata en los puntos críticos de vertido ilegal. Cada reporte
            incluye detalles como la ubicación geográfica exacta, el tipo de residuos detectados y
            una serie de imágenes que documentan el incidente, ofreciendo un panorama completo y
            preciso de la situación.
          </p>
          <p>Entre las funcionalidades principales de la versión uno de la plataforma destacan:</p>
          <p>
            - <span class="coloring">Análisis de Imágenes</span>
            Procesa las imágenes capturadas por las cámaras, identificando y clasificando los
            elementos presentes para facilitar la comprensión del contexto de cada incidente.
          </p>
          <p>
            - <span class="coloring">Generación Automática de Reportes</span>
            Crea reportes detallados que incluyen información relevante como ubicación, tipo de
            residuos y hora del incidente, permitiendo una respuesta más rápida y eficiente por
            parte de las autoridades.
          </p>
          <p>
            - <span class="coloring">Visualización en Tiempo Real</span>
            Muestra un mapa interactivo con los puntos críticos de vertido ilegal identificados,
            permitiendo a los usuarios monitorear y seguir los incidentes en tiempo real.
          </p>
          <p>
            - <span class="coloring">Historial de Incidentes</span>
            Almacena todos los reportes generados para su consulta posterior, facilitando el
            análisis de tendencias y la identificación de patrones de comportamiento.
          </p>
          <p>
            Esta primera versión de la plataforma no solo mejora la eficiencia en la gestión de
            vertidos ilegales, sino que también proporciona una herramienta poderosa para la
            planificación y la prevención a largo plazo. Al centralizar toda la información en un
            solo lugar, facilita la coordinación entre diferentes entidades y promueve una respuesta
            más coordinada y efectiva.
          </p>
          <p>
            El desarrollo de esta plataforma es un hito importante para nuestro proyecto, ya que
            consolida nuestro esfuerzo por ofrecer una solución tecnológica integral para el
            monitoreo y la gestión de residuos en la ciudad. Estamos entusiasmados por las
            posibilidades que esta herramienta ofrece y comprometidos a seguir mejorándola en
            futuras versiones para maximizar su impacto en la protección del medio ambiente y la
            calidad de vida en nuestra comunidad.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
