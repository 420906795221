<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%202.35.48%E2%80%AFp.m..png?alt=media&token=aea6bfd2-9e33-4479-8c8f-8aa98bdfb8af"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Primer Prototipo</a>
      </h3>
      <span><a href="#">26 Agosto 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%202.35.48%E2%80%AFp.m..png?alt=media&token=aea6bfd2-9e33-4479-8c8f-8aa98bdfb8af"
          ></div>
        </div>
        <div class="news_main_title">
          <audio controls style="width: 100%">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-primer-prototipo.mp3?alt=media&token=713b07d0-54ed-4055-8fb3-f1b524df50bd"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          <h3>Primer Prototipo</h3>
          <span><a href="#">26 Agosto 2024</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            El desarrollo de nuestro primer prototipo fue un paso fundamental en la evolución de
            nuestro proyecto. Este concepto inicial tenía un objetivo sencillo pero crucial:
            demostrar que era posible capturar imágenes de manera autónoma y subirlas a un servidor,
            estableciendo así la base tecnológica para un sistema de monitoreo de vertidos ilegales
            de basura.
          </p>
          <p>
            El prototipo consistía en una cámara conectada a Internet mediante la red Wi-Fi de la
            casa, lo que nos permitió probar la funcionalidad en un entorno controlado. El sistema
            tomaba fotos a intervalos regulares y las enviaba a un servidor remoto para su
            almacenamiento. Aunque esta versión inicial no contaba con energía solar ni conectividad
            móvil, nos permitió validar la idea básica de automatizar la captura y transmisión de
            imágenes.
          </p>
          <p>
            Nuestro objetivo principal en esta etapa era probar que el sistema podía funcionar de
            manera autónoma, sin intervención manual, y que las imágenes capturadas se subieran
            correctamente al servidor. Este prototipo nos mostró que, efectivamente, era posible
            monitorear un área específica y almacenar la información visual en una ubicación
            centralizada.
          </p>
          <p>
            Este primer prototipo fue, en esencia, la prueba de concepto que nos mostró que la idea
            podía funcionar. Aunque aún quedaba mucho por desarrollar para llevar el proyecto al
            campo, esta fase inicial nos dio la confianza para avanzar hacia versiones más complejas
            y adaptadas a las condiciones reales de los puntos críticos de vertido ilegal.
          </p>
          <p>
            A partir de aquí, comenzamos a trabajar en soluciones que permitieran la operación del
            sistema en entornos sin acceso a Wi-Fi, integrando conectividad móvil y opciones de
            alimentación energética independientes. Este primer paso nos enseñó que, con las
            herramientas adecuadas, podíamos crear un sistema de monitoreo eficaz y escalable para
            enfrentar el problema del vertido ilegal de basura en nuestra ciudad.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
