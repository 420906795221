<template>
    <div class="slider-section">
      <div class="label-row">
        <label>Número de Equipos: {{ cameras }}</label>
        <!-- Info icon for cameras -->
        <div 
          class="info-icon" 
          @mouseenter="showCameraTooltip = true" 
          @mouseleave="showCameraTooltip = false" 
          @mousemove="updateTooltipPosition"
        >
          <!-- Tooltip for cameras -->
          <div v-if="showCameraTooltip" :style="tooltipStyle" class="tooltip">
            Precio por equipo: ${{ pricePerCamera.toFixed(2) }} USD
          </div>
        </div>
      </div>
      <div class="slider-row">
        <input type="range" v-model="cameraValue" :min="1" :max="50" @input="updateCameraValue" style="width: 70%;" />
        <span class="price-contribution">
          ${{ cameraContribution.toFixed(2) }} USD
        </span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CameraSlider',
    props: {
      cameras: {
        type: Number,
        required: true,
      },
      pricePerCamera: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        cameraValue: this.cameras, // Internal value for the camera slider
        showCameraTooltip: false,  // Track tooltip visibility
        tooltipX: 0,               // X position of the tooltip
        tooltipY: 0,               // Y position of the tooltip
      };
    },
    computed: {
      cameraContribution() {
        return this.cameraValue * this.pricePerCamera;
      },
      tooltipStyle() {
        return {
          top: `${0}px`, 
          left: `${15}px`,
        };
      },
    },
    methods: {
      updateTooltipPosition(e) {
        this.tooltipX = e.clientX;
        this.tooltipY = e.clientY;
      },
      updateCameraValue() {
        this.$emit('update-cameras', this.cameraValue);
      },
    },
  };
  </script>
  
  <style scoped>
  .slider-section {
    margin: 20px 0;
  }
  
  .label-row {
    display: flex;
    align-items: center;
  }
  
  .info-icon {
    display: inline-block;
    background-color: #ddd;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    margin-left: 10px;
    cursor: pointer;
    position: relative;
  }
  
  .tooltip {
    position: absolute;
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    white-space: nowrap;
    pointer-events: none;
    z-index: 100;
  }
  
  .slider-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .price-contribution {
    margin-left: 10px;
    font-weight: bold;
  }
  </style>
  