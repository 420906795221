/* eslint-disable no-unused-vars */
const axios = require('axios');
const teamData = require('./TeamData');
const OurAchievements = require('./OurAchievements');
const OurNews = require('./OurNews');

const chatGPTApi = axios.create({
  baseURL: 'https://api.openai.com/v1',
  headers: {
    'Content-Type': 'application/json',
    Authorization:
      'Bearer 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', // Replace with your actual OpenAI API key
  },
});

const sendMessageToChatGPT = async (messages) => {
  const system = [
    { role: 'system', content: 'You are a persuasive sales representative. Your goal is to present the project in the most compelling way, emphasizing its unique benefits, value, and impact. Focus on understanding the clients needs, addressing their pain points, and positioning the project as the best solution. You should be confident, clear, and persuasive, highlighting key features, return on investment, and competitive advantages.' },
    { role: 'system', content: `Here is the data from the team that is developing the proyect ${teamData}` },
    { role: 'system', content: `Here is the data of our acievements ${OurAchievements}` },
    { role: 'system', content: `Here is the news of the develpment of our project ${OurNews}` },
    { role: 'system', content: 'The response must use proper spacings and punctuations. The response should as short as posible, less than 100 words', },
    { role: 'system', content: 'If contact information is asked, The developer Jorge Andrés García can be contacted at the cellphone +52 6621560190.', },
    { role: 'system', content: 'If anyone asks about the pricing, the prices are available in the section of `precios` in the website' },
    { role: 'system', content: 'If the questing is not related, tell the user in a nice way to ask something related', },
    
  ];

  console.log([...system, ...messages])
  try {
    const response = await chatGPTApi.post('/chat/completions', {
      model: 'gpt-4o-mini',
      messages: [...system, ...messages],
    });
    return { role: 'assistant', content: response.data.choices[0].message.content };
  } catch (error) {
    console.error('Error interacting with ChatGPT:', error);
  }
};

module.exports = {
  sendMessageToChatGPT,
  chatGPTApi,
};
