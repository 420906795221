<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%202.52.17%E2%80%AFp.m..png?alt=media&token=e383aab9-1fa6-4752-b7ee-3d7f63d334ce"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Segundo Prototipo</a>
      </h3>
      <span><a href="#">5 Septiembre 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%202.52.17%E2%80%AFp.m..png?alt=media&token=e383aab9-1fa6-4752-b7ee-3d7f63d334ce"
          ></div>
        </div>
        <div class="news_main_title">
          <div style="width: 100%;">
            <audio controls style="width: 100%;">
              <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-segundo-prototipo.mp3?alt=media&token=f18aff90-3e43-415d-abd2-6c010d544b3c"
              type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </div>
          <h3>Segundo Prototipo - Mejorando la Autonomía y Conectividad</h3>
          <span><a href="#">7 Agosto 2024</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            El desarrollo del segundo prototipo de nuestro sistema de monitoreo de vertidos ilegales
            marcó un avance significativo en cuanto a la autonomía y la conectividad del
            dispositivo. En esta versión, logramos integrar una antena 3G/4G que permite la
            transmisión de imágenes desde ubicaciones remotas, así como un power bank interno que
            extiende la duración de la batería de la cámara hasta 40 horas.
          </p>
          <p>
            Este power bank interno fue una adición crucial para el funcionamiento autónomo del
            sistema, ya que garantiza la alimentación de la cámara y la antena durante periodos
            prolongados sin necesidad de recarga. Sin embargo, aún enfrentamos algunos desafíos
            técnicos que debemos superar para que el prototipo sea completamente funcional en
            condiciones de campo.
          </p>
          <p>
            Uno de los problemas más importantes es que el power bank utilizado no soporta
            "corriente pass-through". Esto significa que no es capaz de cargarse y suministrar
            energía al mismo tiempo. Cuando intentamos conectar el dispositivo a una fuente de
            alimentación externa, como un panel solar, el power bank no permite que la cámara y la
            antena 3G/4G funcionen mientras se carga. Esto representa un desafío considerable para
            la operación continua en zonas sin acceso constante a electricidad, ya que el
            dispositivo debe desconectarse del power bank para cargarse, lo que interrumpe su
            funcionamiento.
          </p>
          <p>
            Además, enfrentamos problemas de estabilidad de voltaje. La batería no mantiene un
            voltaje constante, lo que provoca que, en ocasiones, la antena 3G se reinicie
            inesperadamente durante su funcionamiento. Esto ocurre cuando la demanda de energía de
            la antena supera la capacidad momentánea de entrega del power bank, causando
            fluctuaciones que afectan la estabilidad del sistema. Este reinicio interrumpe la
            conectividad y provoca la pérdida temporal de la capacidad de transmisión de imágenes al
            servidor.
          </p>
          <p>
            El segundo prototipo nos mostró que, si bien hemos avanzado en la autonomía y
            conectividad, aún quedan desafíos técnicos que superar antes de alcanzar una solución
            completamente operativa. La experiencia adquirida en esta etapa es esencial para
            perfeccionar el diseño y asegurar que nuestro sistema sea capaz de funcionar de manera
            ininterrumpida en entornos desafiantes, acercándonos cada vez más a nuestra meta de
            implementar una solución efectiva y sostenible para el monitoreo de vertidos ilegales en
            nuestra ciudad.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
