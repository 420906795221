// teamData.js
const teamData = `
Somos Hargos, un equipo comprometido con el desarrollo de soluciones tecnológicas innovadoras para mejorar la calidad de vida en nuestra ciudad. Estamos desarrollando un sistema de cámaras inteligentes que, mediante el uso de energía solar y algoritmos de inteligencia artificial, monitorea puntos críticos de residuos para combatir el problema de la basura ilegal. Nuestra misión es contribuir a un entorno más limpio y seguro a través de la tecnología y la colaboración ciudadana.
- ¿Qué Ofrecemos?
Monitoreo Inteligente
Plataformas de Gestión
Análisis de Datos
Soluciones Sostenibles
Consultoría Tecnológica

- Habilidades Tecnológicas
Desarrollo de Sistemas de Monitoreo100%
Inteligencia Artificial100%
Desarrollo de Plataformas100%
Tiempo de Respuesta


Integrantes del Equipo
- Jorge Andres García Montiel
CV: 
Jorge Andrés García
Motivated and innovative programmer with a passion for creating new projects. Experienced in data analysis with python, nodejs, typescript, server deployment, vue and mongoDB. Skilled in problem-solving and collaborating in team environments. Seeking opportunities to apply programming expertise and contribute to exciting projects.
EXPERIENCE
Greenapsis, San Pedro, Nuevo León: R+D (hardware developer)
from 2015 to 2020
● Designed and developed automation devices using microcontrollers to enhance and streamline lighting systems in industrial settings.
● Collaborated with a team of engineers to create innovative solutions for industrial lighting control.
● Programmed microcontrollers using C++ to automate lighting processes, encompassing intelligent scheduling and energy optimization.
Etiquetas e impresiones de Mexico (EIM), Hermosillo, Sonora: (fullstack dev, and hardware dev)
from March 2020 - March 2021
● Using vanilla javascript, Jquery and bootstrap I designed and implemented a user-friendly interface that allowed workers to send alerts and report machine status.
● Integrated microcontrollers using c++ to program the logic of the sensors that were able to measure machine’s speed, providing valuable data for performance analysis.
● Implemented a MySQL database to store machine data, facilitating historical analysis and generating performance reports.
Greenapsis, San Pedro, Nuevo Leon: (Fullstack dev) from April 2021 - present
● Developed a React Native mobile application that allows users to e ortlessly explore the various streaming and purchasing options available for movies.
● I utilized Node.js along with Axios to integrate API calls, enabling seamless integration with various movie databases and streaming platforms. This allowed the application to fetch up-to-date information on movies from di erent sources.
Oblatos, 23
83240 Hermosillo, Sonora (52) 662 1560 190 dringenieroandres@gmail.com
Languages and Tools
Javascript (5 years)
NodeJs (5 years)
Python (3 years)
Pandas for data analysis (3 years)
Express.js (3 years) Flask (3 years)
Vue.js (5 years)
React native (3 years) React (3 years)
C++ (8 years) Bootstrap (5 years) Visual Basic (5 years) Excel (8 years)
LANGUAGES
English - Advanced Spanish - Native

Angel Arballo
CV:
Hola! Soy Angel Arballo
Soy originario de Guaymas sonora y estudiante universitario del tecnológico de México campus Hermosillo (ITH) en ing mecatrónica
En los últimos años me eh dedicado a realizar y participar en diversos proyectos y concursos a nivel estatal y nacional
Soy alguien a quien le apasiona tecnología y del cómo podríamos utilizarla para mejorar nuestra calidad de vida
Estoy convencido de la practicidad y futuro del proyecto HARGOS junto a  su implementación en la ciudad de Hermosillo, nuestro propósito es ayudar a que exista una ciudad más limpia para todos y en un futuro poder consolidar el proyecto como un éxito en innovación y resultados.

  CURRICULUM  VITAE


GUADALUPE RICARDO LARIOS RODRIGUEZ

EDUCACION
INSTITUTO TECNOLOGICO DE NOGALES, 1988-1993
CARRERA: INGENIERO INDUSTRIAL EN ELECTRONICA
TITULADO: INSTITUTO TECNOLOGICO DE MEXICALI
TEMA: AUTODIAGNOSTICO DE FALLAS DE  MAQUINARIA EN PROCESO USANDO UN   PLC
POSGRADO
MAESTRIA EN CIENCIAS DE LA INGENIERIA EN MECATRONICA
(candidato a grado)

Estudiando actualmente la Maestría en Ingeniería Mecatrónica
INSTITUTO TECNOLOGICO SUPERIOR DE CAJEME
Actualmente trabajando en la Tesis
Caracterización de Sensores Semiconductores de Imagen con la tecnología  CMOS para Cámaras de Observación Aérea de Ecosistemas Acuáticos

EXPERIENCIA PERSONAL DE TRABAJO

TECHNITRADE S.A. DE C.V.
INGENIERO DE MANTENIMIENTO
 (4 AÑOS)

Actividades

>CALIBRACION A BASCULAS ELECTRONICAS
>REVISION  DE  TARJETAS  ELECTRONICAS PARA BÁSCULAS
>DETECCION Y CORRECCION DE FALLAS EN EQUIPOS ELECTRONICOS
>INSTALACION Y MANTENIMIENTO A BASCULAS ELECTRONICAS PARA VEHICULOS
>INSTALACION Y MANTENIMIENTO DE DETECTORES MAGNETICOS PARA VEHICULOS

PROMOTORA INDUSTRIAL JUAREZ S.A. DE C.V.
SUPERVISOR DE PRODUCCION
 (1 AÑO)

>SUPERVISAR A EL PERSONAL
>REPARACION DE MAQUINARIA EN PROCESO
>SUPERVISAR EL PROCESO DE PRODUCCION
>SUPERVISAR LOS TRABAJOS DE  MANTENIMIENTO

DAEWOO ORION MEXICANA
INGENIERO DE  MANTENIMIENTO
(3 AÑOS)

>PROGRAMACION DE PLC
>REPARACION DE MAQUINARIA EN PROBLEMAS ELECTRICOS
>MODIFICACIONES Y NUEVAS INSTALACIONES DE EQUIPO CONTROLADOS POR  PLC
>INGENIERO DE MANTENIMIENTO ELECTRICO
>LIDER DEL EQUIPO DE INNOVACIONES Y CAUSAS RAIZ EN FALLAS DE PRODUCCION
>LIDER REPARADOR DE FALLAS DE DISEÑO

K Y S    SERVICIOS INDUSTRIALES
INGENIERO  ELECTRICO 
(1 AÑOS)

>REPARACIONES DE EQUIPO ELECTRONICO
>VALIDACIÓN DEL EQUIPO REPARADO
>MONITOREO DEL EQUIPO EN PROCESO

UNIVERSIDAD TECNOLÓGICA DE HERMOSILLO
PROFESOR TIEMPO COMPLETO
(22 AÑOS)

>CLASE  AUTOMATIZACIÓN CON PLC
>CLASE   MICRO CONTROLADORES 
>CLASES PROGRAMACION Y ROBOTICA 
>CLASE    ELECTRONICA
> CLASES…ETC,ETC
>MANEJO DE MINI COMPUTADORA RESPBERRY PI Y TERJETA ARDUINO 
>DIPLOMADO EN LabVIEW
>DIPLOMADO EN EDUCACION BASADA EN COMPETENCIAS
>ESTANCIA ACADEMICA EN EMPRESA SISTEMAS AUTOMATIZADOS (SISA) EN INTEGRACION DE PROYECTOS INDUSTRIALES
>ORIENTAR A LOS ALUMNOS EN BUSQUEDA DE FALLAS Y REPARACIONES  DE MAQUINARIA
`;

module.exports = teamData;