<template>
  <MobileHeaderComponent />
  <!-- Header -->
  <div class="edrea_tm_header">
    <div class="header_inner">
      <div class="logo">
        <div style="display: flex; align-items: center">
          <div style="display: flex; align-items: center">
            <a
              href="#"
              @click="navFuntions.activeSection('home')"
              style="display: flex; align-items: center"
            >
              <img
                style="border-radius: 50%; max-width: 60px; max-height: 60px; margin-right: 10px"
                src="https://firebasestorage.googleapis.com/v0/b/greenapp-a3d0b.appspot.com/o/assets%2FScreenshot%202024-03-07%20at%205.45.20%E2%80%AFp.m..png?alt=media&token=81c1f1be-2564-42f1-b178-d7ac9f6e63ca"
                alt="Logo"
              />
              <h4 class="coloring" style="margin: 0">Hargos</h4>
            </a>
          </div>
        </div>
      </div>
      <div class="menu">
        <Menu />
      </div>
    </div>
  </div>
</template>

<script>
import { navFuntions } from "@/navFuntions";
import Menu from "./Menu.vue";
import MobileHeaderComponent from "./MobileHeaderComponent.vue";
export default {
  name: `HeaderComponent`,
  components: { MobileHeaderComponent, Menu },
  data() {
    return {
      navFuntions,
    };
  },
};
</script>
