<template>
    <div class="list_inner">
      <div class="image">
        <img src="img/thumbs/1-1.jpg" alt="" />
        <div
          class="main"
          data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fimgonline-com-ua-resize-EJKm2hhSyPxcE6.jpg?alt=media&token=420a05a7-9cf3-47db-a622-b90e4b1ecfce"
        ></div>
      </div>
      <div class="details">
        <h3>Recorrido con Patrulla Verde</h3>
        <span>Puntos Críticos</span>
      </div>
      <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

      <!-- Portfolio Popup Informations -->
      <div class="edrea_tm_hidden_content">
        <div class="portfolio_popup_details">
          <div class="top_image">
            <img
              src="img/thumbs/4-3.jpg"
              alt=""
            />
            <div
              class="main"
              data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fimgonline-com-ua-resize-EJKm2hhSyPxcE6.jpg?alt=media&token=420a05a7-9cf3-47db-a622-b90e4b1ecfce"
              style="background-image: url('img/portfolio/4.jpg')"
            ></div>
          </div>
          <div class="details">
            <div style="width: 100%">
            <audio controls style="width: 100%">
              <source
                src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FRecorrido%20con%20la%20Patrulla%20Verde.mp3?alt=media&token=ad2cb609-3532-49c5-a8e1-e86558fffc75"
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </div>
            <h3>Recorrido con Patrulla Verde</h3>
            <span>Puntos Críticos</span>
          </div>
          <div class="text">
            <p>
              Gracias al recorrido que realizamos con la Patrulla Verde, pudimos adaptar nuestro proyecto para que pudiera instalarse en áreas sin acceso a conexión eléctrica. Durante estos recorridos, observamos de primera mano los puntos más críticos de vertido ilegal de basura y comprendimos las limitaciones de infraestructura en dichos lugares.
            </p>
            <p>
              A partir de esta experiencia, desarrollamos un sistema de monitoreo autónomo, alimentado por energía solar, capaz de operar en condiciones adversas y en ubicaciones remotas. Esta adaptación nos permitió crear una solución más robusta y efectiva, que apoya de manera más eficiente la labor de la Patrulla Verde en la identificación y gestión de puntos críticos de residuos.
            </p>
            <p>
              El resultado ha sido una propuesta conjunta de mejora que integra cámaras inteligentes y una plataforma de gestión que facilita el monitoreo en tiempo real, reforzando el esfuerzo colectivo para mantener nuestra ciudad más limpia y segura.
            </p>
          </div>
          <div class="additional_images">
            <ul class="gallery_zoom">
              <li>
                <div class="list_inner">
                  <div class="image">
                    <img src="img/thumbs/4-2.jpg" alt="" />
                    <div
                      target="_blank"
                      class="main"
                      data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.07.19%E2%80%AFp.m..png?alt=media&token=91b7f38f-bf33-40cf-b64f-63652e8ca000"
                    ></div>
                    <a
                      target="_blank"
                      class="edrea_tm_full_link zoom"
                      href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.07.19%E2%80%AFp.m..png?alt=media&token=91b7f38f-bf33-40cf-b64f-63652e8ca000"
                    ></a>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <div class="image">
                    <img src="img/thumbs/4-2.jpg" alt="" />
                    <div
                      target="_blank"
                      class="main"
                      data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.07.04%E2%80%AFp.m..png?alt=media&token=2079a667-6bbc-402c-9e3a-c6f1dde1ad88"
                    ></div>
                    <a
                      target="_blank"
                      class="edrea_tm_full_link zoom"
                      href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.07.04%E2%80%AFp.m..png?alt=media&token=2079a667-6bbc-402c-9e3a-c6f1dde1ad88"
                    ></a>
                  </div>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <div class="image">
                    <img src="img/thumbs/4-2.jpg" alt="" />
                    <div
                      target="_blank"
                      class="main"
                      data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.06.33%E2%80%AFp.m..png?alt=media&token=46c1c819-58fa-4c97-a1a2-a2b9d56a3924"
                    ></div>
                    <a
                      target="_blank"
                      class="edrea_tm_full_link zoom"
                      href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%2010.06.33%E2%80%AFp.m..png?alt=media&token=46c1c819-58fa-4c97-a1a2-a2b9d56a3924"
                    ></a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /Portfolio Popup Informations -->
    </div>
</template>

<script>

import {  SwiperSlide } from "swiper/vue";

export default {
    components: {
        SwiperSlide,
    }

}
</script>


<style scoped>
  .list_inner .details {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    left: 20px;
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: all 0.3s ease;
  }
</style>