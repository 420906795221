<template>
 
</template>

<script>
export default {
  name: `SettingsComponent`,
  data() {
  },
  methods: {

  },
  mounted() {
  },
};
</script>

<style scoped>
.colors li {
  margin: 0 2.5px;
}
</style>
