<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%2012.42.24%E2%80%AFp.m..png?alt=media&token=e3aea8b4-2682-47bb-96b6-54c50a6b2ea1"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Primera Visita a la Patrulla Verde</a>
      </h3>
      <span><a href="#">20 Junio 2024</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%2012.42.24%E2%80%AFp.m..png?alt=media&token=e3aea8b4-2682-47bb-96b6-54c50a6b2ea1"
          ></div>
        </div>
        <div class="news_main_title">
          <div style="width: 100%;">
            <audio controls style="width: 100%">
            <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fa-visita.mp3?alt=media&token=07080d0f-38cc-4096-bbbe-b68986121fc7"
              type="audio/mpeg"
            />
            Your browser does not support the audio element.
          </audio>
          </div>
          <h3>Primera Visita a la Patrulla Verde</h3>
          <span><a href="#">20 Junio 2024</a></span>
          
        </div>
        <div class="text">
          <p>
            Un momento fundamental en el desarrollo de nuestro proyecto fue nuestra primera visita a
            las instalaciones de la Patrulla Verde. Gracias a esta oportunidad, no solo tuvimos la
            posibilidad de conocer a los funcionarios que forman parte de esta importante iniciativa
            ambiental en Hermosillo, sino también de entender mejor su trabajo y los desafíos que
            enfrentan diariamente en la protección de nuestro entorno.
          </p>
          <p>
            Durante la visita, fuimos recibidos por el equipo de la Patrulla Verde, quienes nos
            mostraron sus instalaciones y nos explicaron en detalle cómo operan para identificar y
            combatir el vertido ilegal de basura en la ciudad. Fue una experiencia enriquecedora, ya
            que pudimos conocer de primera mano el compromiso y esfuerzo que estos funcionarios
            ponen en su labor para mantener la ciudad limpia y ordenada.
          </p>
          <p>
            Recorrimos sus oficinas, conocimos el equipo y la logística detrás de cada operativo.
            También nos explicaron los procedimientos que siguen para recibir reportes de la
            ciudadanía, su gestión de datos y la planificación de recorridos para monitorear las
            áreas más afectadas. Esta experiencia nos ayudó a comprender la magnitud del problema y
            las limitaciones a las que se enfrentan, como la falta de recursos y la complejidad de
            monitorear zonas amplias y de difícil acceso.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
