<template>
  <div class="edrea_tm_modalbox" :class="open ? 'opened' : ''">
    <div class="box_inner">
      <div class="close">
        <a href="#" @click="close"><i class="icon-cancel"></i></a>
      </div>
      <div class="description_wrap">
        <div class="about_popup_details">
          <div class="left">
            <div class="left_inner">
              <div class="author">
                <div class="avatar_image">
                  <img src="https://firebasestorage.googleapis.com/v0/b/greenapp-a3d0b.appspot.com/o/assets%2FScreenshot%202024-03-07%20at%205.45.20%E2%80%AFp.m..png?alt=media&token=81c1f1be-2564-42f1-b178-d7ac9f6e63ca" alt="" />
                  <div class="main" data-img-url="https://firebasestorage.googleapis.com/v0/b/greenapp-a3d0b.appspot.com/o/assets%2FScreenshot%202024-03-07%20at%205.45.20%E2%80%AFp.m..png?alt=media&token=81c1f1be-2564-42f1-b178-d7ac9f6e63ca"></div>
                </div>
                <div class="short">
                  <h3 class="name">
                    {{ aboutData.firstName }}
                    <span class="coloring">{{ aboutData.lastName }}</span>
                  </h3>
                  <h3 class="job">
                    <AnimationText />
                  </h3>
                </div>
              </div>
              <div class="list">
                <ul>
                  <li>
                    <div class="list_inner">
                      <i class="icon-user"></i>
                      <span
                        >{{ aboutData.firstName }}
                        {{ aboutData.lastName }}</span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-calendar"></i>
                      <span>{{ aboutData.bithday }}</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-location"></i>
                      <span
                        ><a href="#" class="href_location">{{
                          aboutData.address
                        }}</a></span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-phone"></i>
                      <span
                        ><a href="#">{{ aboutData.phn }}</a></span
                      >
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <i class="icon-mail-1"></i>
                      <span
                        ><a style="font-size: 14px;" :href="`mailto:${aboutData.email}`">{{
                          aboutData.email
                        }}</a></span
                      >
                    </div>
                  </li>
                </ul>
              </div>
              <div class="edrea_tm_button full">
                <a href="img/about/1.jpg" download>Download CV</a>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="right_inner">
              <div class="biography">
                <div class="about_title">
                  <h3>
                    <span>About <span class="coloring">Me</span></span>
                  </h3>
                </div>
                <div class="text">
                  <p>
                    Somos 
                    <span>

                      Hargos, 
                    </span>
                    un equipo comprometido con el desarrollo de soluciones tecnológicas innovadoras para mejorar la calidad de vida en nuestra ciudad. Estamos desarrollando un sistema de cámaras inteligentes que, mediante el uso de energía solar y algoritmos de inteligencia artificial, monitorea puntos críticos de residuos para combatir el problema de la basura ilegal. Nuestra misión es contribuir a un entorno más limpio y seguro a través de la tecnología y la colaboración ciudadana.
                  </p>
                </div>
              </div>
              <div class="service">
                <div class="about_title">
                  <h3>
                    <span>¿Qué <span class="coloring">Ofrecemos?</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(service, i) in aboutData.serviceLists" :key="i">
                      <i class="icon-right-dir"></i>{{ service }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="prog_skill">
                <div class="about_title">
                  <h3>
                    <span
                      >Habilidades <span class="coloring">Tecnológicas</span></span
                    >
                  </h3>
                </div>
                <div class="oki_progress">
                  <div
                    class="progress_inner"
                    v-for="(skill, i) in aboutData.skills.programming"
                    :key="i"
                  >
                    <span
                      ><span class="label">{{ skill.name }}</span
                      ><span class="number">{{ skill.value }}%</span></span
                    >
                    <div class="background">
                      <div class="bar open">
                        <div
                          class="bar_in"
                          :style="{ width: `${skill.value}%` }"
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="partners">
                <div class="about_title">
                  <h3>
                    <span>Equipo <span class="coloring">Integrantes</span></span>
                  </h3>
                </div>
                <div class="list">
                  <ul>
                    <li v-for="(partner, i) in aboutData.partnersLogos">
                      <div class="list_inner">
                        <img :src="partner" alt="" />
                        <a class="cavani_tm_full_link" href="#"></a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import AnimationText from "../AnimationText.vue";
export default {
  name: `AboutPopup`,
  components: {
    Swiper,
    SwiperSlide,
    AnimationText,
  },
  data() {
    return {
      open: false,
      // data
      aboutData: {
        firstName: "Equipo",
        lastName: "Hargos",
        bithday: "07 Marzo 2024",
        address: "Hermosillo, Sonora",
        phn: "+52 6621 56 01 90",
        email: "mygypsyservice@gmail.com",
        serviceLists: [
          "Monitoreo Inteligente",
          "Plataformas de Gestión",
          "Análisis de Datos",
          "Soluciones Sostenibles",
          "Consultoría Tecnológica",
        ],
        skills: {
          programming: [
            { name: "Desarrollo de Sistemas de Monitoreo", value: "100" },
            { name: "Inteligencia Artificial", value: "100" },
            { name: "Desarrollo de Plataformas", value: "100" },
            { name: "Tiempo de Respuesta", value: "100" },
          ],
          language: [
            { name: "English", value: "95" },
            { name: "Russian", value: "80" },
            { name: "Arabic", value: "90" },
          ],
        },
        education: [
          {
            year: "2014 - 2016",
            unv: "Oxford Univercity",
            degree: "Master Degree",
          },
          {
            year: "2010 - 2014",
            unv: "Texas Univercity",
            degree: "Bachelor Degree",
          },
          {
            year: "2008 - 2010",
            unv: "Simone College",
            degree: "Associate Degree",
          },
        ],
        working: [
          {
            year: "2018 - running",
            company: "Envato Elements",
            deg: "Exclusive Author",
          },
          {
            year: "2015 - 2018",
            company: "Avo Corporation",
            deg: "Content Manager",
          },
          {
            year: "2012 - 2015",
            company: "FC Barcelona",
            deg: "Football Player",
          },
        ],
        partnersLogos: [
          "https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%204.16.16%E2%80%AFp.m..png?alt=media&token=3951c3bb-084f-42e1-912b-9901ffe51e55",
          "https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%204.17.36%E2%80%AFp.m..png?alt=media&token=ec58f8fd-47c1-4282-99ef-2a38a512fef7",
          "https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%204.18.13%E2%80%AFp.m..png?alt=media&token=6678f4a6-3f4f-4d90-ad3e-3c786437544c",
        ],
      },
    };
  },
  methods: {
    close() {
      this.open = false;
    },
  },
  mounted() {
    const aboutPopup = document.getElementById("aboutPopup");
    aboutPopup.addEventListener("click", () => {
      this.open = true;
    });

    const myCircle = document.querySelectorAll(".myCircle");
    myCircle.forEach((circle) => {
      let progress = circle.getAttribute("data-value");
      let span = document.createElement("span");
      span.className = "number";
      const value = `${progress * 100}%`;
      span.innerHTML = value;
      circle.appendChild(span);
      circle.style.setProperty("--progress", value);
      circle.setAttribute("aria-valuenow", value);
    });
  },
  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.testimonial {
  overflow: hidden;
}
.owl-carousel.swiper {
  overflow: unset;
}

.myCircle {
  --hue: 220;
  --holesize: 67%;
  --track-bg: hsl(233, 34%, 92%);

  min-width: 110px;
  min-height: 110px;
  display: grid;
  align-items: center;
  justify-items: center;
  place-items: center;
  position: relative;
  margin-bottom: 5px;
}
.myCircle::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  z-index: -1;
  background: conic-gradient(
    var(--main-color) var(--progress, 0%),
    rgba(255, 255, 255, 0.15) var(--progress, 0%) 100%
  );

  mask-image: radial-gradient(
    transparent var(--holesize),
    black calc(var(--holesize) + 1px)
  );
}
</style>
