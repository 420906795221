<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/4-3.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%203.58.47%E2%80%AFp.m..png?alt=media&token=e965f5a8-24fc-4a73-936f-3a97726cdb87"
      ></div>
      <a class="edrea_tm_full_link news_popup" href="#"></a>
    </div>
    <div class="details">
      <h3>
        <a href="#">Agencia Municipal de Desarrollo Económico</a>
      </h3>
      <span><a href="#">Futuro</a></span>
    </div>

    <!-- News Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="news_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-2.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-28%20at%203.58.47%E2%80%AFp.m..png?alt=media&token=e965f5a8-24fc-4a73-936f-3a97726cdb87"
          ></div>
        </div>
        <div class="news_main_title">
          <div style="width: 100%;">
            <audio controls style="width: 100%;">
              <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FAgencia%20Municipal.mp3?alt=media&token=77b3874a-7fe1-4c14-88dc-84b52a64905b"
              type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </div>
          <h3>Próxima Presentación en la Agencia Municipal de Desarrollo Económico</h3>
          <span><a href="#">Futuro</a></span>
          <div></div>
        </div>
        <div class="text">
          <p>
            En los próximos días, presentaremos nuestro proyecto ante la Agencia Municipal de
            Desarrollo Económico, donde compartiremos nuestra visión y avances en la creación de un
            sistema de monitoreo autónomo para combatir el vertido ilegal de basura en Hermosillo.
            Este evento representa una gran oportunidad para dar a conocer nuestra iniciativa y
            demostrar cómo nuestra tecnología puede contribuir a mejorar el entorno urbano de la
            ciudad.
          </p>
          <p>
            Esperamos que los funcionarios de la agencia puedan brindarnos su apoyo para continuar
            desarrollando y expandiendo este proyecto. Con su colaboración, podremos llevar nuestra
            solución a más puntos críticos y hacer una diferencia real en la protección del medio
            ambiente y la calidad de vida de nuestra comunidad. Estamos entusiasmados por esta
            oportunidad y confiamos en que juntos podremos crear un Hermosillo más limpio y
            sostenible.
          </p>
        </div>
      </div>
    </div>
    <!-- /News Popup Informations -->
  </div>
</template>
