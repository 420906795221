<template>
  <div class="edrea_tm_section" id="about">
    <div class="section_inner">
      <div class="edrea_tm_about">
        <div class="left">
          <div class="image">
            <img src="img/thumbs/1-1.jpg" alt="" />
            <div class="main" data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2Fimgonline-com-ua-resize-EJKm2hhSyPxcE6.jpg?alt=media&token=420a05a7-9cf3-47db-a622-b90e4b1ecfce"></div>
          </div>
        </div>
        <div class="right">
          <div class="short">
            <h3 class="name">Somos <span class="coloring">Hargos</span></h3>
            <h3 class="job">
              <AnimationText />
            </h3>
          </div>
          <div class="text">
            <p>
              Somos <span>Hargos</span>, un equipo comprometido con el desarrollo de soluciones tecnológicas innovadoras para mejorar la calidad de vida en nuestra ciudad. Estamos desarrollando un sistema de cámaras inteligentes que, mediante el uso de energía solar y algoritmos de inteligencia artificial, monitorea puntos críticos de residuos para combatir el problema de la basura ilegal. Nuestra misión es contribuir a un entorno más limpio y seguro a través de la tecnología y la colaboración ciudadana.            </p>
          </div>
          <div class="edrea_tm_button" id="aboutPopup">
            <a href="#">Leer más</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AnimationText from "./AnimationText.vue";
export default {
  name: `AboutComponent`,
  data() {
    return {
      open: false,
    };
  },
  components: {
    AnimationText,
  },
};
</script>

<style scoped>
.edrea_tm_modalbox {
  top: -90px;
}
</style>
