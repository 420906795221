<template>
  <div class="list_inner">
    <div class="image">
      <img src="img/thumbs/1-1.jpg" alt="" />
      <div
        class="main"
        data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.44.11%E2%80%AFp.m..png?alt=media&token=2095c9c0-161d-44b2-8bb1-d6ea2ff4e149"
      ></div>
    </div>
    <div class="details">
      <h3>Plataforma de Gestión de Reportes</h3>
      <span>Página Web</span>
    </div>
    <a class="edrea_tm_full_link portfolio_popup" href="#"></a>

    <!-- Portfolio Popup Informations -->
    <div class="edrea_tm_hidden_content">
      <div class="portfolio_popup_details">
        <div class="top_image">
          <img src="img/thumbs/4-3.jpg" alt="" />
          <div
            class="main"
            data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.44.11%E2%80%AFp.m..png?alt=media&token=2095c9c0-161d-44b2-8bb1-d6ea2ff4e149"
            style="background-image: url('img/portfolio/4.jpg')"
          ></div>
        </div>
        <div class="details">
          <div style="width: 100%;">
            <audio controls style="width: 100%;">
              <source
              src="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FPlataforma%20de%20Gestio%CC%81n%20de%20Reportes.mp3?alt=media&token=b85eb594-1c8c-4aad-9e1f-111064242318"
              type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
          </div>
          <h3>Plataforma de Gestión de Reportes</h3>
          <span>Página Web</span>
        </div>
        <div class="text">
          <p>
            La Plataforma de Gestión de Reportes es una propuesta innovadora que busca transformar
            la manera en que se administran los incidentes de vertidos ilegales en nuestra ciudad.
            Diseñada para mejorar la eficiencia y la coordinación de las autoridades locales, esta
            herramienta permitirá crear, visualizar y asignar reportes de manera interactiva,
            facilitando una respuesta rápida y efectiva ante situaciones críticas.
          </p>
          <p>
            A futuro, esperamos que esta plataforma se convierta en un recurso esencial para las
            autoridades municipales, no solo para gestionar los residuos sólidos, sino también para
            enfrentar otros problemas urbanos, como la seguridad y el monitoreo ambiental. Con
            funcionalidades avanzadas de mapeo y filtros personalizados, la plataforma ofrecerá una
            visualización precisa y en tiempo real de los problemas urbanos, permitiendo una gestión
            proactiva y anticipada.
          </p>
          <p>
            Nuestra visión es que esta herramienta sea implementada de manera integral en la ciudad,
            optimizando el uso de los recursos disponibles y promoviendo una cultura de
            transparencia y eficiencia en la administración pública. Con el apoyo adecuado,
            esperamos ampliar su alcance para que se convierta en una pieza clave en la
            modernización de la gestión urbana, contribuyendo a la creación de un entorno más
            limpio, seguro y ordenado para todos.
          </p>
          <p>
            Esta propuesta representa un paso adelante hacia un futuro donde la tecnología y la
            colaboración ciudadana trabajen de la mano para resolver los desafíos de nuestra ciudad,
            y estamos comprometidos a seguir desarrollando y mejorando esta solución para alcanzar
            ese objetivo.
          </p>
        </div>
        <div class="additional_images">
          <ul class="gallery_zoom">
            <li>
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    target="_blank"
                    class="main"
                    data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.44.53%E2%80%AFp.m..png?alt=media&token=4f394e53-6398-4ed0-be9d-f4e87810de0f"
                  ></div>
                  <a
                    target="_blank"
                    class="edrea_tm_full_link zoom"
                    href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.44.53%E2%80%AFp.m..png?alt=media&token=4f394e53-6398-4ed0-be9d-f4e87810de0f"
                  ></a>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    target="_blank"
                    class="main"
                    data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.45.15%E2%80%AFp.m..png?alt=media&token=8fdb80ef-76d3-4d45-9783-67f2e30a19b3"
                  ></div>
                  <a
                    target="_blank"
                    class="edrea_tm_full_link zoom"
                    href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.45.15%E2%80%AFp.m..png?alt=media&token=8fdb80ef-76d3-4d45-9783-67f2e30a19b3"
                  ></a>
                </div>
              </div>
            </li>
            <li>
              <div class="list_inner">
                <div class="image">
                  <img src="img/thumbs/4-2.jpg" alt="" />
                  <div
                    target="_blank"
                    class="main"
                    data-img-url="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.53.53%E2%80%AFp.m..png?alt=media&token=3d51b532-0283-4065-b136-b39618120254"
                  ></div>
                  <a
                    target="_blank"
                    class="edrea_tm_full_link zoom"
                    href="https://firebasestorage.googleapis.com/v0/b/p-verde.appspot.com/o/assets%2FScreenshot%202024-09-27%20at%208.53.53%E2%80%AFp.m..png?alt=media&token=3d51b532-0283-4065-b136-b39618120254"
                  ></a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- /Portfolio Popup Informations -->
  </div>
</template>

<script>
import { SwiperSlide } from "swiper/vue";

export default {
  components: {
    SwiperSlide,
  },
};
</script>


<style scoped>
  .list_inner .details {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    left: 20px;
    background-color: #fff;
    padding: 10px 20px 10px 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: all 0.3s ease;
  }
</style>