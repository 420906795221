<template>
  <div class="edrea_tm_section animated" id="home">
    <div class="section_inner">
      <div class="edrea_tm_home">
        <h3 class="name">Equipo <span class="coloring">Hargos</span></h3>
        <small style="color: white">Somos el 
          <span class="coloring">
            "ver-de" 
          </span>
          Hermosillo
        </small>
        <h3 class="job">
          <AnimationText />
        </h3>
        <div class="edrea_tm_button transition_link">
          <a style="width: 150px" href="#portfolio" @click="navFuntions.activeSection('portfolio')"
            >Logros</a
          >
        </div>
        <div class="edrea_tm_button transition_link" style="margin-top: 10px;">
          <a style="width: 150px" href="#news" @click="navFuntions.activeSection('news')"
            >Noticias</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { navFuntions } from "@/navFuntions";
import AnimationText from "./AnimationText.vue";

export default {
  name: `HomeComponent`,
  data() {
    return {
      navFuntions,
    };
  },
  components: { AnimationText },
};
</script>

<style></style>
