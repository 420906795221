// teamData.js
const OurAchievements = `
Próxima Presentación en la Agencia Municipal de Desarrollo Económico
Futuro
En los próximos días, presentaremos nuestro proyecto ante la Agencia Municipal de Desarrollo Económico, donde compartiremos nuestra visión y avances en la creación de un sistema de monitoreo autónomo para combatir el vertido ilegal de basura en Hermosillo. Este evento representa una gran oportunidad para dar a conocer nuestra iniciativa y demostrar cómo nuestra tecnología puede contribuir a mejorar el entorno urbano de la ciudad.
Esperamos que los funcionarios de la agencia puedan brindarnos su apoyo para continuar desarrollando y expandiendo este proyecto. Con su colaboración, podremos llevar nuestra solución a más puntos críticos y hacer una diferencia real en la protección del medio ambiente y la calidad de vida de nuestra comunidad. Estamos entusiasmados por esta oportunidad y confiamos en que juntos podremos crear un Hermosillo más limpio y sostenible.

Próximos Pasos - Implementación en el Terreno
Futuro
Con el tercer prototipo completamente funcional, estamos listos para llevar nuestro sistema de monitoreo al siguiente nivel. Ahora buscamos el apoyo de la Patrulla Verde para asignarnos una ubicación específica en la ciudad donde podamos probar el dispositivo en condiciones reales.
Esta primera implementación nos permitirá validar el rendimiento del prototipo en un entorno real, monitoreando un punto crítico de vertido ilegal de basura. Esperamos que este despliegue inicial demuestre la eficacia de nuestro sistema y siente las bases para expandir la red de monitoreo en más ubicaciones estratégicas de la ciudad.


Tercer Prototipo - Autonomía, Control Remoto y Resistencia Mejorados
23 Septiembre 2024
En la evolución continua de nuestro sistema de monitoreo de vertidos ilegales de basura, hemos desarrollado el tercer prototipo, el cual presenta mejoras significativas en cuanto a autonomía, eficiencia energética y resistencia ambiental. Este nuevo diseño incorpora tecnologías avanzadas que permiten una operación más robusta y adaptable en entornos desafiantes, acercándonos cada vez más a una solución completamente operativa en el campo.
El tercer prototipo ahora incluye una batería que soporta "PassThrough", lo que significa que puede cargarse y suministrar energía al mismo tiempo. Esta capacidad es fundamental para su funcionamiento continuo, ya que permite conectar el dispositivo a un panel solar sin interrumpir la operación de la cámara y la antena 3G/4G. De esta manera, el sistema puede mantenerse activo incluso durante la carga, asegurando una vigilancia constante en los puntos críticos.
Además, hemos integrado una batería interna adicional que garantiza un voltaje constante. Esta mejora elimina los problemas de inestabilidad eléctrica que experimentamos en versiones anteriores, como los reinicios inesperados de la antena de internet. Ahora, el sistema puede operar de manera más fiable y consistente, evitando interrupciones en la transmisión de datos. Adicionalmente, esta batería interna cuenta con un monitor que nos permite verificar el estado de la batería de forma remota, brindándonos información en tiempo real sobre el nivel de carga y la salud del sistema energético.
Otra innovación clave en este prototipo es la capacidad de ahorro de energía mediante un PowerBoost, que nos permite controlar de manera remota el encendido y apagado de la antena de internet y la cámara. Con esta funcionalidad, podemos gestionar el consumo energético de manera eficiente, activando el sistema solo cuando se detecta movimiento o en momentos específicos del día, y apagándolo cuando no es necesario, maximizando así la duración de la batería.
Para mejorar la durabilidad del sistema en condiciones ambientales adversas, hemos añadido un ventilador interno que ayuda a reducir la temperatura dentro de la caja, evitando el sobrecalentamiento de los componentes electrónicos en días calurosos. Esta adición es crucial para mantener un rendimiento óptimo, especialmente en los entornos extremos en los que operará el dispositivo.
Además, hemos diseñado la caja del sistema para que sea a prueba de agua, asegurando su funcionamiento incluso en condiciones de lluvia o alta humedad. Esta protección contra el agua nos permite instalar el dispositivo en exteriores sin temor a que se dañe por la exposición a los elementos, garantizando su operatividad en todas las estaciones del año.
Con estas mejoras, el tercer prototipo se ha convertido en una solución más completa y resistente para el monitoreo de vertidos ilegales. La combinación de autonomía energética, control remoto, reducción de temperatura y resistencia al agua nos permite enfrentar de manera efectiva los desafíos que encontramos en el campo, asegurando un monitoreo constante y fiable en los puntos críticos.
Seguimos trabajando para perfeccionar el diseño y asegurar que nuestro sistema sea capaz de enfrentar cualquier desafío en el campo, proporcionando una solución tecnológica sólida para combatir el vertido ilegal de basura en nuestra ciudad. Estamos convencidos de que, con estas nuevas capacidades, estamos más cerca de implementar un sistema de monitoreo efectivo y sostenible que ayudará a proteger nuestro entorno y a mejorar la calidad de vida en nuestra comunidad.

Versión Uno de la Plataforma de Análisis y Gestión de Reportes
7 Septiembre 2024
Con el objetivo de optimizar el monitoreo y la gestión de vertidos ilegales de basura en la ciudad, hemos desarrollado la versión uno de nuestra plataforma digital, diseñada para analizar imágenes capturadas por nuestro sistema de cámaras y generar reportes de manera eficiente y automatizada. Esta herramienta marca un avance significativo en nuestro proyecto, ya que facilita la recopilación, el análisis y la visualización de datos en tiempo real, mejorando la respuesta ante estos incidentes.
La plataforma integra las imágenes capturadas por nuestros prototipos de cámaras, que se suben automáticamente al servidor, y las procesa mediante inteligencia artificial para identificar la presencia de basura, personas o vehículos en las áreas monitoreadas. Gracias a la integración con Vertex AI de Google, el sistema es capaz de clasificar de manera precisa los distintos tipos de residuos y detectar patrones de comportamiento, proporcionando información detallada y contextualizada.
Además de las capacidades de análisis, la plataforma permite la generación de reportes automáticos que se envían directamente a las autoridades locales, facilitando la toma de decisiones y la acción inmediata en los puntos críticos de vertido ilegal. Cada reporte incluye detalles como la ubicación geográfica exacta, el tipo de residuos detectados y una serie de imágenes que documentan el incidente, ofreciendo un panorama completo y preciso de la situación.
Entre las funcionalidades principales de la versión uno de la plataforma destacan:
- Análisis de Imágenes Procesa las imágenes capturadas por las cámaras, identificando y clasificando los elementos presentes para facilitar la comprensión del contexto de cada incidente.
- Generación Automática de Reportes Crea reportes detallados que incluyen información relevante como ubicación, tipo de residuos y hora del incidente, permitiendo una respuesta más rápida y eficiente por parte de las autoridades.
- Visualización en Tiempo Real Muestra un mapa interactivo con los puntos críticos de vertido ilegal identificados, permitiendo a los usuarios monitorear y seguir los incidentes en tiempo real.
- Historial de Incidentes Almacena todos los reportes generados para su consulta posterior, facilitando el análisis de tendencias y la identificación de patrones de comportamiento.
Esta primera versión de la plataforma no solo mejora la eficiencia en la gestión de vertidos ilegales, sino que también proporciona una herramienta poderosa para la planificación y la prevención a largo plazo. Al centralizar toda la información en un solo lugar, facilita la coordinación entre diferentes entidades y promueve una respuesta más coordinada y efectiva.
El desarrollo de esta plataforma es un hito importante para nuestro proyecto, ya que consolida nuestro esfuerzo por ofrecer una solución tecnológica integral para el monitoreo y la gestión de residuos en la ciudad. Estamos entusiasmados por las posibilidades que esta herramienta ofrece y comprometidos a seguir mejorándola en futuras versiones para maximizar su impacto en la protección del medio ambiente y la calidad de vida en nuestra comunidad.

Segundo Prototipo - Mejorando la Autonomía y Conectividad
7 Agosto 2024
El desarrollo del segundo prototipo de nuestro sistema de monitoreo de vertidos ilegales marcó un avance significativo en cuanto a la autonomía y la conectividad del dispositivo. En esta versión, logramos integrar una antena 3G/4G que permite la transmisión de imágenes desde ubicaciones remotas, así como un power bank interno que extiende la duración de la batería de la cámara hasta 40 horas.
Este power bank interno fue una adición crucial para el funcionamiento autónomo del sistema, ya que garantiza la alimentación de la cámara y la antena durante periodos prolongados sin necesidad de recarga. Sin embargo, aún enfrentamos algunos desafíos técnicos que debemos superar para que el prototipo sea completamente funcional en condiciones de campo.
Uno de los problemas más importantes es que el power bank utilizado no soporta "corriente pass-through". Esto significa que no es capaz de cargarse y suministrar energía al mismo tiempo. Cuando intentamos conectar el dispositivo a una fuente de alimentación externa, como un panel solar, el power bank no permite que la cámara y la antena 3G/4G funcionen mientras se carga. Esto representa un desafío considerable para la operación continua en zonas sin acceso constante a electricidad, ya que el dispositivo debe desconectarse del power bank para cargarse, lo que interrumpe su funcionamiento.
Además, enfrentamos problemas de estabilidad de voltaje. La batería no mantiene un voltaje constante, lo que provoca que, en ocasiones, la antena 3G se reinicie inesperadamente durante su funcionamiento. Esto ocurre cuando la demanda de energía de la antena supera la capacidad momentánea de entrega del power bank, causando fluctuaciones que afectan la estabilidad del sistema. Este reinicio interrumpe la conectividad y provoca la pérdida temporal de la capacidad de transmisión de imágenes al servidor.
El segundo prototipo nos mostró que, si bien hemos avanzado en la autonomía y conectividad, aún quedan desafíos técnicos que superar antes de alcanzar una solución completamente operativa. La experiencia adquirida en esta etapa es esencial para perfeccionar el diseño y asegurar que nuestro sistema sea capaz de funcionar de manera ininterrumpida en entornos desafiantes, acercándonos cada vez más a nuestra meta de implementar una solución efectiva y sostenible para el monitoreo de vertidos ilegales en nuestra ciudad.

Integración con Inteligencia Artificial
30 Agosto 2024
A medida que nuestro proyecto avanzaba, nos dimos cuenta de que capturar imágenes de manera automática no era suficiente para abordar de manera efectiva el problema del vertido ilegal de basura. Necesitábamos una forma de analizar y clasificar las imágenes de manera inteligente, para que el sistema pudiera identificar de manera precisa los tipos de residuos y la actividad sospechosa en los puntos críticos. Así es como decidimos integrar la inteligencia artificial en nuestro proyecto.
Exploramos tres opciones de tecnologías de inteligencia artificial para el análisis de imágenes: Vertex AI de Google, OpenAI Vision, y Amazon Rekognition. Cada una de estas plataformas ofrecía distintas ventajas en términos de precisión, facilidad de uso y capacidad de personalización. A continuación, se detalla nuestra experiencia con cada una de ellas:
- OpenAI Vision: Esta opción nos ofrecía un análisis general de las imágenes, pero carecía de la capacidad de ser muy específica en la descripción de los elementos presentes. Aunque identificaba objetos y patrones, no alcanzaba el nivel de detalle que necesitábamos para nuestro propósito de monitoreo de residuos específicos y actividades relacionadas con el vertido ilegal.
- Amazon Rekognition: La herramienta de Amazon mostró buenos resultados en la detección de objetos y en el reconocimiento facial, pero sus capacidades para describir escenas complejas y clasificar residuos no eran tan precisas como esperábamos. Aunque es una excelente opción para reconocimiento general, no cumplía con la necesidad de categorizar diferentes tipos de basura y vehículos en detalle.
- Vertex AI de Google: Finalmente, encontramos en Vertex AI la solución que mejor se adaptaba a nuestras necesidades. Esta herramienta no solo ofrecía un análisis detallado y preciso de las imágenes, sino que también nos permitía personalizar los modelos y ser muy específicos en nuestros prompts. Podíamos entrenar el modelo para identificar y clasificar diferentes tipos de residuos, distinguir vehículos y personas en la escena, e incluso detectar patrones de comportamiento relacionados con el vertido ilegal.
La capacidad de Vertex AI para generar descripciones detalladas de las imágenes fue un gran avance para nuestro proyecto. Con esta herramienta, pudimos implementar una solución que no solo detectaba la presencia de basura, sino que también proporcionaba información específica sobre el tipo de residuos y las circunstancias del vertido. Esto nos permitió generar reportes más completos y útiles para las autoridades, optimizando la respuesta ante estos incidentes.


Primer Prototipo
26 Agosto 2024
El desarrollo de nuestro primer prototipo fue un paso fundamental en la evolución de nuestro proyecto. Este concepto inicial tenía un objetivo sencillo pero crucial: demostrar que era posible capturar imágenes de manera autónoma y subirlas a un servidor, estableciendo así la base tecnológica para un sistema de monitoreo de vertidos ilegales de basura.
El prototipo consistía en una cámara conectada a Internet mediante la red Wi-Fi de la casa, lo que nos permitió probar la funcionalidad en un entorno controlado. El sistema tomaba fotos a intervalos regulares y las enviaba a un servidor remoto para su almacenamiento. Aunque esta versión inicial no contaba con energía solar ni conectividad móvil, nos permitió validar la idea básica de automatizar la captura y transmisión de imágenes.
Nuestro objetivo principal en esta etapa era probar que el sistema podía funcionar de manera autónoma, sin intervención manual, y que las imágenes capturadas se subieran correctamente al servidor. Este prototipo nos mostró que, efectivamente, era posible monitorear un área específica y almacenar la información visual en una ubicación centralizada.
Este primer prototipo fue, en esencia, la prueba de concepto que nos mostró que la idea podía funcionar. Aunque aún quedaba mucho por desarrollar para llevar el proyecto al campo, esta fase inicial nos dio la confianza para avanzar hacia versiones más complejas y adaptadas a las condiciones reales de los puntos críticos de vertido ilegal.
A partir de aquí, comenzamos a trabajar en soluciones que permitieran la operación del sistema en entornos sin acceso a Wi-Fi, integrando conectividad móvil y opciones de alimentación energética independientes. Este primer paso nos enseñó que, con las herramientas adecuadas, podíamos crear un sistema de monitoreo eficaz y escalable para enfrentar el problema del vertido ilegal de basura en nuestra ciudad.

Entendiendo los Desafíos del Proyecto
22 Junio 2024

Un paso clave en el desarrollo de nuestro proyecto fue el recorrido que realizamos junto a un conductor de la Patrulla Verde. Esta experiencia nos permitió conocer de primera mano los puntos críticos de vertido ilegal de basura en Hermosillo y entender mejor los desafíos que conlleva monitorear y controlar estas actividades en el terreno.
Durante el recorrido, visitamos varios sitios problemáticos donde se arrojan grandes cantidades de basura y escombros, no solo por personas individuales, sino también por camionetas y camiones de construcción. Muchos de estos lugares se encuentran en zonas remotas, sin acceso a electricidad ni infraestructura básica, lo que representa un desafío considerable para cualquier tipo de monitoreo continuo.
El conductor de la Patrulla Verde compartió con nosotros su experiencia diaria y cómo, en muchos casos, los infractores aprovechan momentos específicos de baja actividad para arrojar sus desechos, lo que dificulta atraparlos en el acto. Además, notamos que el equipo de la Patrulla Verde depende en gran medida del uso de WhatsApp para gestionar los reportes de vertido ilegal. Utilizan esta aplicación para recibir y enviar información, incluyendo imágenes y ubicaciones de los puntos críticos, lo que complica el seguimiento y la coordinación efectiva de acciones.
Al observar esta situación, comprendimos la necesidad de una plataforma de gestión más estructurada y eficiente que centralizara la información y facilitara la coordinación en tiempo real. Decidimos que nuestro proyecto debía enfocarse no solo en el monitoreo visual, sino también en ofrecer una solución digital que mejorara la gestión de reportes y la comunicación interna del equipo.
En cuanto a la logística de las cámaras, nos enfrentamos a varios desafíos. Dado que muchos de los puntos críticos están en áreas sin acceso a energía eléctrica, fue necesario desarrollar un sistema de cámaras completamente autónomo, alimentado por energía solar y equipado con baterías de larga duración. Además, en estos entornos, la conectividad es limitada, lo que nos llevó a implementar un sistema de transmisión de datos mediante redes 3G/4G para subir las fotos capturadas a la plataforma.
Este recorrido fue fundamental para adaptar nuestro proyecto a las realidades del terreno y desarrollar una solución tecnológica viable y efectiva. A partir de esta experiencia, hemos trabajado en perfeccionar nuestro sistema, integrando cámaras autónomas, conectividad 3G y una plataforma digital que facilite la gestión de reportes y la coordinación con la Patrulla Verde.
Esta experiencia no solo enriqueció nuestro proyecto, sino que también reforzó nuestro compromiso de seguir trabajando para crear un entorno urbano más limpio y sostenible, apoyando a quienes, como la Patrulla Verde, se esfuerzan diariamente por proteger nuestro entorno.

Primera Visita a la Patrulla Verde
20 Junio 2024
Un momento fundamental en el desarrollo de nuestro proyecto fue nuestra primera visita a las instalaciones de la Patrulla Verde. Gracias a esta oportunidad, no solo tuvimos la posibilidad de conocer a los funcionarios que forman parte de esta importante iniciativa ambiental en Hermosillo, sino también de entender mejor su trabajo y los desafíos que enfrentan diariamente en la protección de nuestro entorno.
Durante la visita, fuimos recibidos por el equipo de la Patrulla Verde, quienes nos mostraron sus instalaciones y nos explicaron en detalle cómo operan para identificar y combatir el vertido ilegal de basura en la ciudad. Fue una experiencia enriquecedora, ya que pudimos conocer de primera mano el compromiso y esfuerzo que estos funcionarios ponen en su labor para mantener la ciudad limpia y ordenada.
Recorrimos sus oficinas, conocimos el equipo y la logística detrás de cada operativo. También nos explicaron los procedimientos que siguen para recibir reportes de la ciudadanía, su gestión de datos y la planificación de recorridos para monitorear las áreas más afectadas. Esta experiencia nos ayudó a comprender la magnitud del problema y las limitaciones a las que se enfrentan, como la falta de recursos y la complejidad de monitorear zonas amplias y de difícil acceso.

Un Paso hacia la Colaboración con el Gobierno
23 Marzo 2024
Otro momento crucial en la evolución de nuestro proyecto fue la presentación que realizamos en el Ágora de la Universidad Tecnológica de Hermosillo (ITH), donde tuvimos la oportunidad de exponer nuestra iniciativa frente al presidente municipal y diversos funcionarios encargados de la gestión urbana y el medio ambiente de la ciudad.
Durante esta presentación, mostramos la visión de nuestro proyecto para combatir el vertido ilegal de basura en puntos críticos de Hermosillo. A pesar de que en ese momento nuestro prototipo era solo una plataforma digital que simulaba un sistema de monitoreo con un mapa interactivo y alertas visuales, el interés generado fue notable. Explicamos cómo esta herramienta podría transformarse en un sistema completo con cámaras inteligentes y monitoreo en tiempo real para optimizar la respuesta de las autoridades.
La participación en este evento fue un gran paso adelante para nosotros, ya que nos permitió establecer contacto directo con los líderes locales y explorar la posibilidad de una colaboración más estrecha con el gobierno municipal. Los funcionarios presentes mostraron interés en la implementación de una solución tecnológica como la nuestra para enfrentar los desafíos relacionados con el manejo de residuos sólidos en la ciudad.
Esta presentación no solo nos brindó la oportunidad de dar a conocer nuestra propuesta a quienes tienen el poder de decisión en la ciudad, sino que también nos permitió recibir retroalimentación constructiva y adaptar nuestro enfoque para alinearnos mejor con las necesidades y prioridades del gobierno local. Salimos de ese encuentro con la certeza de que nuestra idea podía convertirse en una herramienta útil para la comunidad y con la motivación de seguir trabajando para hacerla realidad.
El evento en el Ágora fue un punto de inflexión que reafirmó nuestro compromiso de transformar este proyecto en una solución efectiva para el manejo de residuos en Hermosillo, abriendo la puerta a futuras colaboraciones con las autoridades y a la posibilidad de llevar nuestra iniciativa al siguiente nivel.

Un Reconocimiento al Esfuerzo
10 Marzo 2024
Uno de los momentos más importantes en el desarrollo de nuestro proyecto fue nuestra presentación en el evento organizado en Parque La Ruina, un centro de encuentro para ideas innovadoras y proyectos que buscan transformar Hermosillo. En esta ocasión, tuvimos la oportunidad de mostrar nuestra visión para enfrentar el problema del vertido ilegal de basura en la ciudad.
Aunque en ese momento nuestro prototipo no contaba con hardware ni dispositivos de monitoreo, logramos desarrollar una plataforma digital que simulaba el funcionamiento de un sistema de monitoreo urbano. La página web, aún en una etapa inicial, mostraba un mapa interactivo con marcadores en los puntos críticos de vertido ilegal y algunas alertas visuales, representando cómo se podría gestionar la información y notificar a las autoridades en tiempo real.
A pesar de ser un prototipo sin funcionalidad completa, nuestra propuesta fue bien recibida por su enfoque innovador y su potencial para convertirse en una solución efectiva. Explicamos cómo esta plataforma podría evolucionar en un sistema más robusto con cámaras y sensores que, en conjunto con la página, ofrecerían una herramienta poderosa para la administración y control de vertidos ilegales.
Gracias a esta presentación, obtuvimos el tercer lugar en la competencia, un reconocimiento que nos llenó de motivación para seguir trabajando y desarrollando la idea. Como premio, recibimos dos computadoras y 5,000 pesos, recursos que utilizamos para continuar mejorando nuestra propuesta y avanzar hacia un sistema funcional.
Este evento fue un punto de partida crucial para nuestro equipo, pues nos permitió recibir retroalimentación valiosa y darnos cuenta del gran interés que generaba nuestra idea, incluso en su fase más temprana. La experiencia en Parque La Ruina nos impulsó a transformar este concepto inicial en una solución tecnológica real que pudiera ayudar a mantener nuestra ciudad más limpia y ordenada.

Jóvenes exponen proyectos a favor del medio ambiente en el Smart City Hackathon 2024
14 de marzo 2024
HERMOSILLO, Sonora.- Con la participación de 10 equipos finalistas se realizó la edición 2024 de Smart City Hackathon organizado por el Ayuntamiento de Hermosillo donde destacó la propuesta “Impresión Verde”.
En el área del Galerón del Parque La Ruina, cada finalista presentó su proyecto para solucionar retos del municipio como la acumulación de residuos, distribución de agua potable y las olas de calor que afectan a Hermosillo.
Luego de una breve exposición el jurado expuso sus dudas en una ronda de preguntas y respuestas para evaluar las mejores propuestas de las cuales Hargos obtuvo el tercer lugar; Orbe Urbano, el segundo; e Impresión Verde el primero.
Impresión Verde consiste en una máquina que convierte botellas de plástico en filamentos de 3D para poder crear piezas y como somos biomédica le damos un enfoque en crear prótesis para gente necesitada o animales”, destacó Jessica Alín Alvarado Montes, estudiante de la Universidad Estatal de Sonora.
Después de 36 horas de trabajo con mentoría, personal académico y del Ayuntamiento de Hermosillo los equipos presentaron sus proyectos fundamentados con un plan de negocios.
En relación al reconocimiento de People’s Choice que contó con más de 2 mil votantes, el proyecto Watch-A resaltó con la propuesta “innova el sistema de cobro, corte y toma de lectura de agua potable”, que ofrece el Ayuntamiento de Hermosillo.
Se contó con la participación de Caffenio, Tec Milenio, Unidep, Tec de Monterrey, Unison, Instituto Tecnológico de Hermosillo (ITH), CT Internacional, BI50N y el Ayuntamiento.

Hackaton Smartcities
7 Marzo 2024
Todo comenzó en la Universidad Tecnológica de Hermosillo (ITH), donde se llevó a cabo el Hackathon SmartCities, un evento con inscripción abierta para todos aquellos apasionados por la tecnología y la innovación. Fue en este entorno dinámico y colaborativo donde nació la idea que hoy impulsa nuestro proyecto de monitoreo de residuos.
La competencia reunió a estudiantes, profesionales y entusiastas con el objetivo de desarrollar soluciones innovadoras para los problemas urbanos. En este contexto, nuestro equipo se formó casi por casualidad, unido por un objetivo común: encontrar una manera efectiva y económica de abordar el problema del vertido ilegal de basura en nuestra ciudad.
Durante el evento, discutimos y evaluamos diferentes propuestas, enfocándonos en crear un sistema de cámaras accesibles y de bajo costo que pudiera instalarse en puntos críticos de la ciudad para monitorear y registrar estos incidentes. La idea era simple pero poderosa: utilizar tecnología asequible para vigilar las zonas más afectadas, generando evidencia visual que pudiera ayudar a las autoridades a tomar acción.
Nuestra propuesta inicial se centró en la instalación de cámaras económicas alimentadas por energía solar, capaces de capturar imágenes y videos que serían revisados manualmente. Aunque no contábamos con sofisticados algoritmos ni inteligencia artificial en ese momento, la meta era crear una solución práctica y asequible que pudiera implementarse fácilmente en las zonas más problemáticas.
Este enfoque nos llevó a obtener el tercer lugar en el hackathon, siendo reconocidos por nuestra creatividad y por ofrecer una solución viable a un problema urbano significativo. Fue un gran impulso para nosotros, motivándonos a seguir desarrollando y mejorando la idea.
El Hackathon SmartCities marcó el inicio de nuestro camino. Aunque nuestro proyecto ha evolucionado desde entonces, el espíritu de crear soluciones accesibles y efectivas sigue siendo el núcleo de nuestra misión. Este evento no solo nos dio la oportunidad de materializar una idea, sino también de conectar con personas e instituciones que comparten nuestra visión de un entorno urbano más limpio y ordenado.
`;

module.exports = OurAchievements;