<template>
  <div>
    <!-- Botón flotante para abrir el chat -->
    <div class="chat-button" @click="toggleChat">
      <img
        src="https://img.icons8.com/ios-filled/50/ffffff/chat--v1.png"
        alt="Chat Logo"
        class="chat-icon"
      />
    </div>

    <!-- Ventana del chat -->
    <div v-if="isChatOpen" class="chat-window">
      <div class="chat-header">
        <img
          src="https://img.icons8.com/ios-filled/50/ffffff/chat--v1.png"
          alt="Chat Logo"
          class="chat-header-logo"
        />
        <h4>Asistente IA Hargos</h4>
        <button @click="toggleChat" class="close-chat">&times;</button>
      </div>
      <div class="chat-body" style="">
        <!-- Muestra la conversación -->
        <div v-for="(msg, index) in conversation" :key="index" class="message">
          <p style="white-space: pre-line" :class="msg.role">
            <!-- {{ msg.role === "assistant" ? "" : "" }}  -->
            {{ msg.content }}
          </p>
        </div>
      </div>
      <div class="chat-footer">
        <input
          v-model="newMessage"
          ref="messageInput"
          type="text"
          placeholder="Escribe un mensaje..."
          @keyup.enter="sendMessage"
        />
        <button @click="sendMessage">Enviar</button>
      </div>
    </div>
  </div>
</template>

<script>
const { sendMessageToChatGPT } = require("./AskBot");

export default {
  data() {
    return {
      isChatOpen: false,
      conversation: [
        {
          role: "assistant",
          content:
            "¡Hola! Puedes preguntarme cualquier cosa acerca de Hargos. ¿En qué puedo ayudarte?",
        },
      ],
      newMessage: "",
    };
  },
  methods: {
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
    },
    sendMessage() {
      if (this.newMessage.trim() === "") return;
      this.conversation.push({
        role: "user",
        content: this.newMessage,
      });
      this.respondAsAssistant();
      this.newMessage = "";
      this.$nextTick(() => {
      this.$refs.messageInput.focus();
    });
    },
    async respondAsAssistant() {
      const conversationCopy = this.conversation.map((message) => ({
        role: message.role,
        content: message.content,
      }));
      const res = await sendMessageToChatGPT(conversationCopy);
      this.conversation = [...conversationCopy, res];
    },
  },
};
</script>

<style scoped>
/* Botón flotante de chat */
.chat-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  padding: 15px;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;
  width: 60px;
  height: 60px;
}

.chat-button:hover {
  background-color: #0056b3;
}

.chat-icon {
  width: 28px;
  height: 28px;
}

/* Ventana del chat */
.chat-window {
  position: fixed;
  bottom: 80px; /* Adjustment to position above the floating button */
  right: 20px;
  width: 600px;
  max-width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; /* Ensure column layout */
  height: 600px; /* Adjust height as needed */
  max-height: 70%;
  overflow: hidden;
}

.chat-header {
  background-color: #35a440;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chat-header h4 {
  margin: 0;
}

.close-chat {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.chat-header-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.chat-body {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column; /* Los mensajes se mostrarán en orden normal */
  justify-content: flex-end; /* Comienza los mensajes desde la parte inferior */
  overflow-y: auto;
  background-color: #f9f9f9;
}
.chat-footer {
  display: flex;
  padding: 10px;
  background-color: #f1f1f1;
  border-top: 1px solid #ddd; /* Optional: adds a border to separate the footer */
}

.chat-footer input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 16px; /* Evita el zoom en dispositivos iPhone */
}

.chat-footer button {
  background-color: #35a440;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.chat-footer button:hover {
  background-color: #35a440;
}
/* Estilos de mensajes */
/* Estilos de mensajes */
.message p {
  padding: 8px;
  margin: 5px 0;
  border-radius: 10px;
  max-width: 90%;
  word-wrap: break-word;
}

/* Mensajes del usuario (alineados a la derecha) */
.user {
  background-color: #d1e7ff;
  align-self: flex-end;
  text-align: right;
  width: 100%;
}

/* Mensajes del asistente (alineados a la izquierda) */
.assistant {
  background-color: #e1e1e1;
  align-self: flex-start;
  text-align: left;
}

/* Ajuste para pantallas móviles */
@media screen and (max-width: 600px) {
  .chat-window {
    width: 95%; /* Aumenta el ancho en dispositivos móviles */
    right: 2.5%;
  }

  .chat-button {
    bottom: 10px;
    right: 10px;
  }
}
</style>
