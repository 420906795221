<template>
    <!-- Informative Section for Camera Details -->
    <div class="info-section">
      <div class="info-header">
        <div class="info-title">
          <h4>Cámara</h4>
          <p class="info-description">5MPx cámara</p>
        </div>
        <div class="info-icon" @click="toggleInfo">ℹ️</div>
      </div>
      <!-- Tooltip showing more details -->
      <div v-if="showInfo" class="tooltip">
        <p>Detalles adicionales: Cámara de 5 megapíxeles, 1080p resolución, ideal para vigilancia.</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showInfo: false, // Track whether the info tooltip is shown
      };
    },
    methods: {
      toggleInfo() {
        this.showInfo = !this.showInfo; // Toggle the visibility of the info tooltip
      },
    },
  };
  </script>
  
  <style>
  .info-section {
    margin: 20px 0;
  }
  
  .info-section {
    display: flex;
    flex-direction: column;
  }
  
  .info-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
  }
  
  .info-title h4 {
    margin: 0;
  }
  
  .info-title p {
    margin: 5px 0 0;
    color: #c1c1c1;
  }
  
  .info-icon {
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  .tooltip {
    margin-top: 10px;
    padding: 10px;
    border-radius: 5px;
    max-width: 300px;
    font-size: 0.9rem;
    color: #c1c1c1;
    border: 1px solid #c1c1c1; /* Added border with color #555 */
  }
  </style>
  